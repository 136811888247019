import React from 'react'
import { FaRegCopy } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';

const ShopBanner = ({data}) => {
    const navigator=useNavigate()
  return (
    <motion.div initial={{y:-10}} animate={{y:0}} className='w-full my-4 min-h-sm flex flex-col md:flex-row items-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  p-4 '>
            <img className="flex w-full md:w-[300px] rounded-xl transition-all duration-300 rounded-lg blur-none hover:blur-sm"  src={data.image} />
            <div className='flex flex-col items-center md:items-start justify-center md:justify-between md:ml-4 mt-4 md:mt-0 '>
                <div className='text-center md:text-left'>
                    <h2 className='dark:text-gray-100 font-semibold text-xl md:text-3xl font-display'>{data.name}</h2>
                    <p className='text-sm md:text-md'>{data.bio}</p>
                    <p className='text-sm md:text-md'>
                        {data.phones.map(a=>(<span>{a}</span>))}
                    </p>
                    <p className='text-sm md:text-md'>{data.email}</p>
                    
                    <p className='text-sm md:text-md'>Address</p>
                    <p className='text-sm md:text-md'>{data.country}</p>
                    <p className='font-bold text-sm md:text-md'>{data.type}</p>
                </div>
                 {/**SHOP Domain md */}
                <div className='hidden md:flex flex-col w-full items-start justify-start mt-4'>
                    
                    <div class="flex relative w-full ">
                            <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://www.oleyn.org/927" required />
                            <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <FaRegCopy />
                            </button>
                            <button type="submit" class="hidden md:block p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <MdModeEdit  />
                            </button>
                        </div>
                        <button type="submit" class="md:hidden w-full text-sm p-2 mt-4  font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <p className='text-sm md:text-md'>Change Domain</p>
                        </button>
                        <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={()=>navigator("/shop-management")}  className="text-white w-full my-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{<p>Manage Shop</p>}</motion.button>
                    </div>
                </div>
                
        </motion.div>
  )
}

export default ShopBanner
