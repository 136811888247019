import React from 'react'
import Navbar from '../../Components/Navbar'

export default function FAQs() {
  return (
      <div className='min-h-screen w-full p-8  flex flex-col items-center justify-center '>
          <Navbar/>
          <h2 className='text-xl md:text-5xl text-center font-bold mb-4 '>Some common questions about <span className='text-primary-500'>bebsha.ai</span></h2>
         
      </div>
  )
}
