import React, { useState } from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { SketchPicker } from "react-color";

function TextEditToolbar({
  fontSize,
  onIncreaseFontSize,
  onDecreaseFontSize,
  fontFamily,
  onFontFamilyChange,
  removeText,
  changeColor,
  layerUp,
  layerDown
}) {
  const fontFamilies = [
    "Arial",
    "Montserrat",
    "Times New Roman",
    "Lato",
    "Roboto",
    "Poppin",
    "Cedarville Cursive"
  ];

  // State to control the color picker visibility
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000"); // Default color

  // Function to handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  // Function to handle color selection and close the color picker
  const handleColorSelect = () => {
    changeColor(selectedColor);
    setColorPickerVisible(false);
  };

  return (
    <div className="absolute w-full flex flex-wrap items-center justify-end top-4 right-2">
      <div className="font-size-control">
      <Tooltip title="Layer Up" arrow>
        <button className="py-1 px-2 md:p-2 mx-1  bg-white border border-gray-300 md:shadow-md -md hover:bg-gray-100" onClick={layerUp}>
          <ArrowUpwardRoundedIcon/>
        </button>
      </Tooltip>
      <Tooltip title="Layer Down" arrow>
        <button className="py-1 px-2 md:p-2 mx-1 bg-white border border-gray-300 md:shadow-md -md hover:bg-gray-100" onClick={layerDown}>
          <ArrowDownwardRoundedIcon/>
        </button>
      </Tooltip>
        <Tooltip title="Decrease Font Size" arrow>
          <button className="py-1 px-2 md:p-2 ml-1 bg-white border border-gray-300 md:shadow-md -l-md hover:bg-gray-100" onClick={onDecreaseFontSize}>
            <RemoveIcon />
          </button>
        </Tooltip>
        <span className="border border-gray-300 md:shadow-md my-2 py-1 md:py-2 px-4">{fontSize}</span>
        <Tooltip title="Increase Font Size" arrow>
          <button className="py-1 px-2 md:p-2 md:mr-1 bg-white border border-gray-300 md:shadow-md -r-md hover:bg-gray-100" onClick={onIncreaseFontSize}>
            <AddIcon/>
          </button>
        </Tooltip>
      </div>
      <div className="font-family-control">
        <Tooltip title="Select Font Family" arrow>
          <Select
            value={fontFamily}
            onChange={(e) => onFontFamilyChange(e.target.value)}
            displayEmpty
            className="max-h-8 md:max-h-10 text-xs w-[100px] md:w-[200px] md:shadow-md"
            inputProps={{ "aria-label": "Select Font Family" }}
          >
            {fontFamilies.map((family) => (
              <MenuItem className="py-2" key={family} value={family}>
                {family}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
        <Tooltip title="Change Text Color" arrow>
          <button
            className="py-1 px-2 md:p-2 ml-2 bg-white border border-gray-300 md:shadow-md -md hover:bg-gray-100"
            onClick={() => setColorPickerVisible(true)}
          >
            <FormatColorFillIcon />
          </button>
        </Tooltip>
        <Tooltip title="Remove Text" arrow>
          <button className="py-1 px-2 md:p-2 mx-2 bg-white border border-gray-300 md:shadow-md -md hover:bg-gray-100" onClick={removeText}>
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
      {colorPickerVisible && (
        <div className="color-picker" style={{ zIndex: 999 }}>
          {/* Set the z-index to a high value */}
          <SketchPicker
            color={selectedColor}
            onChange={handleColorChange}
            disableAlpha={true}
          />
          <button onClick={handleColorSelect}>Select</button>
        </div>
      )}
    </div>
  );
}

export default TextEditToolbar;
