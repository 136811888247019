import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from 'axios'
import { BASE_URL } from "../../Data/apiData";
import { CircularProgress } from "@mui/material";
import {RiUploadCloud2Line} from 'react-icons/ri'
import { Close } from "@mui/icons-material";
import { MdClose } from "react-icons/md";
async function getShopCategoriesForShop(shopId) {
    try {
      const response = await axios.get(
        `${BASE_URL}/shop_categories/shop/${shopId}`
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching shop categories:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  }

export default function CreateProductModal({setCreatingProduct,reloadPage}) {
    const shopId=localStorage.getItem("bebsha-store")
    const [categories, setCategories] = useState([]);
    const [feedback, setFeedback] = useState({ type: "", message: "" });
    const [loading,setLoading]=useState(false)
    const [uploadingImage,setUploadingImage]=useState(false)
    const [imageUploaded,setImageUploaded]=useState(false)
    const [changeImage,setChangeImage]=useState(false)
    const [shopImage,setShopImage]=useState(null)

    const [productData, setProductData] = useState({
      shop_id: shopId,
      shop_category_id: [],
      sub_category_id: null,
      image: null,
      name: "",
      previous_price: null,
      current_price: 0,
      product_code: "",
      quantity: 0,
      warranty: "",
      description: "",
      active: false,
    });
    async function createProduct(productData) {
        
      console.log(JSON.stringify(productData));
      try {
        const response = await axios.post(`${BASE_URL}/products`, productData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return response.data;
      } catch (error) {
        console.error(
          "Error creating product:",
          error.response ? error.response.data : error.message
        );
        throw error;
      }

    }
    useEffect(() => {
      async function fetchCategories() {
        try {
          const categories = await getShopCategoriesForShop(shopId);
          setCategories(
            categories.map((category) => ({
              value: category.id,
              label: category.name,
            }))
          );
        } catch (error) {
          console.error(error);
        }
      }
      fetchCategories();
    }, [shopId]);
  
    const handleInputChange = (e) => {
      const { name, value, type } = e.target;
      setProductData({
        ...productData,
        [name]: type === "number" && value < 0 ? 0 : value,
      });
    };
  
    const handleFileChange = async(e) => {
        const reader = new FileReader();
            reader.onloadend = () => {
            setShopImage(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setProductData({
            ...productData,
            image: e.target.files[0],
        });
        setImageUploaded(true)

    };
  
    const handleSelectChange = (selectedOptions) => {
      setProductData({
        ...productData,
        shop_category_id: selectedOptions.map((option) => option.value),
      });
    };
  
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
      
        try {
            // Upload the image to imgbb
            const formData = new FormData();
            formData.append("key", "22cc4babb6278d7a23eedbbc4acf37f7"); // Replace with your actual IMGBB API key
            formData.append("image", productData.image);
            //formData.append("expiration", 600);
    
            const response = await fetch("https://api.imgbb.com/1/upload", {
            method: "POST",
            body: formData,
            });
    
            if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            const imageUrl = responseData.data.url;
    
            // Update productData with the image URL
            const updatedProductData = {
            ...productData,
            images: [imageUrl],
            };
    
            // Create the product with the updated data
            await createProduct(updatedProductData);
            setFeedback({
            type: "success",
            message: "Product created successfully!",
            });
        } catch (error) {
            console.error("Error during fetch:", error);
            setFeedback({ type: "error", message: "Failed to create product." });
        }
        setLoading(false)
        setCreatingProduct(false)
        reloadPage()
    };
    
    if(loading){
        return(<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="p-8 flex space-x-2 max-w-2xl mx-auto bg-white rounded-lg max-h-[800px] overflow-y-auto">
                <CircularProgress/>
                <p className="text-lg font-semibold">Creating Product for You</p>
            </div>
        </div>)
    }
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <div className="relative p-8 m-4 md:max-w-2xl bg-white rounded-lg max-h-[700px] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-2">Create Product</h2>
          <button onClick={()=>setCreatingProduct(false)} className="absolute top-2 right-2 "><MdClose className="hover:color-gray-500"/></button>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="max-w-xl mx-auto">
                <label  className="block text-sm font-medium text-gray-900 dark:text-white">Product Image Upload</label>
                <div className='relative max-w-xl mx-auto min-h-sm flex flex-col items-center rounded-lg border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 p-4'>
                    {!imageUploaded && !uploadingImage && <div>
                        <div className="flex items-center justify-center h-full w-full ">
                            <label className="flex flex-col items-center justify-center w-full h-2/3 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <RiUploadCloud2Line size={32} className="text-gray-500 dark:text-gray-400"/>
                            </div>
                            <input type="file" className="hidden" onChange={handleFileChange}/>
                            </label>
                        </div>
                        <p className='text-sm text-center text-gray-400'>Upload a product image. The recommended size is 500x500. Maximum file size 4MB</p>
                    </div>
                    }
                    {uploadingImage && <div>Uploding Image...</div>}
                    {shopImage!=null && <img src={shopImage} className='w-[200px]'/>}
                    {shopImage!=null && <button onClick={()=>{
                        setImageUploaded(false)
                        setShopImage(null)
                    }} className="absolute top-2 right-2"><Close/></button>}
                    
                    
                </div>
            </div>
            <div>
              <label className="block text-sm font-medium">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={productData.name}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
            <div className="flex space-x-2">
                <div className="flex-1 h-full">
                    <label className="block text-sm font-medium">Category </label>
                    <Select isMulti options={categories} onChange={handleSelectChange} />
                </div>
                <div className="flex-1">
                <label className="block text-sm font-medium">Product Code</label>
                <input
                    type="text"
                    name="product_code"
                    value={productData.product_code || ""}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                />
                </div>
            </div>
            {/* <div>
              <label className="block text-sm font-medium">Sub Category</label>
              <input
                type="text"
                name="sub_category_id"
                value={productData.sub_category_id || ''}
                onChange={handleInputChange}
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div> */}
            
            <div className="w-full flex space-x-2">
                <div className="flex-1">
                    <label className="block text-sm font-medium">Previous Price</label>
                    <input
                        type="number"
                        name="previous_price"
                        value={productData.previous_price || ""}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                    />
                </div>
                <div className="flex-1">
                    <label className="block  text-sm font-medium">
                        Current Price <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="number"
                        name="current_price"
                        value={productData.current_price}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                    
                    />
                </div>
            </div>
            
            
            <div>
              <label className="block text-sm font-medium">Quantity</label>
              <input
                type="number"
                name="quantity"
                value={productData.quantity || ""}
                onChange={handleInputChange}
                className="border border-gray-300 rounded px-3 py-2 w-full"
                min="0"
                step="1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Warranty</label>
              <input
                type="text"
                name="warranty"
                value={productData.warranty || ""}
                onChange={handleInputChange}
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Description</label>
              <textarea
                name="description"
                value={productData.description || ""}
                onChange={handleInputChange}
                className="border border-gray-300 rounded px-3 py-2 w-full"
              ></textarea>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="active"
                checked={productData.active}
                onChange={() =>
                  setProductData({ ...productData, active: !productData.active })
                }
                className="mr-2"
              />
              <label className="text-sm font-medium">Active</label>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Add Product
            </button>
          </form>
        </div>
      </div>
    );
  }