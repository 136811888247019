import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer } from "react-konva";
import Konva from "konva";
import "./Styles/canvas.css";
import ItemsList from "./ItemsList";
import ImageComponent from "./ImageComponent";
import CanvasBackground from "./CanvasBackground";
import ImageEditToolbar from "./ImageEditToolbar";
import TextEditToolbar from "./TextEditToolBar";
import TextComponent from "./TextComponent";
import { Close, Delete, Edit, Save } from "@mui/icons-material";
import imgbbUpload from "imgbb-image-uploader";
import { BASE_URL } from "../../Data/apiData";
function Canvas({preset_image,hideCanvas,changeURLFromCanvas}) {
  const stageWidth = 1024;
  const stageHeight = 1024;
  const [stageDimensions, setStageDimensions] = useState({
    width: stageWidth,
    height: stageHeight,
    scale: 1,
  });
  const stageRef = useRef();
  const containerRef = useRef();
  const [dragUrl, setDragUrl] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [items, setItems] = useState([]);

  const [showMainOptions, setShowMainOptions] = useState(false);

  const handleIncreaseFontSize = () => {
    if (selectedId !== null && selectedId.startsWith("text")) {
      const updatedItems = items.map((item) =>
        item.id === selectedId ? { ...item, fontSize: item.fontSize + 1 } : item
      );
      setItems(updatedItems);
    }
  };

  const handleDecreaseFontSize = () => {
    if (selectedId !== null && selectedId.startsWith("text")) {
      const updatedItems = items.map((item) =>
        item.id === selectedId && item.fontSize > 5
          ? { ...item, fontSize: item.fontSize - 1 }
          : item
      );
      setItems(updatedItems);
    }
  };

  const handleFontFamilyChange = (family) => {
    if (selectedId !== null && selectedId.startsWith("text")) {
      const updatedItems = items.map((item) =>
        item.id === selectedId ? { ...item, fontFamily: family } : item
      );
      setItems(updatedItems);
    }
  };

  const handleResize = () => {
    let sceneWidth = containerRef.current.clientWidth;
    let scale = sceneWidth / stageWidth;
    setStageDimensions({
      width: stageWidth * scale,
      height: stageHeight * scale,
      scale: scale,
    });
  };

  const handleDownloadImage = async () => {
    await setSelectedId(null)
    const stage = stageRef.current;
    const width = stage.attrs.width;
    const height = stage.attrs.height;
    const pixelRatio = 1024 / width;

    // Create a temporary layer to add a white background
    const layer = new Konva.Layer();
    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: width * pixelRatio,
      height: height * pixelRatio,
      fill: "white",
    });
    layer.add(background);
    stage.add(layer);
    layer.moveToBottom(); // Move the background to the bottom layer
    stage.draw();

    // Export the canvas to a Data URL
    const uri = stage.toDataURL({ pixelRatio: pixelRatio });

    // Remove the temporary background
    layer.destroy();
    stage.draw();

    const link = document.createElement("a");
    link.download = "board-image.png";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // const handleSendToAPI = async () => {
  //   const stage = stageRef.current;
  //   const width = stage.attrs.width;
  //   const height = stage.attrs.height;
  //   const pixelRatio = 1024 / width;
  
  //   // Create a temporary layer to add a white background
  //   const layer = new Konva.Layer();
  //   const background = new Konva.Rect({
  //     x: 0,
  //     y: 0,
  //     width: width * pixelRatio,
  //     height: height * pixelRatio,
  //     fill: "white",
  //   });
  //   layer.add(background);
  //   stage.add(layer);
  //   layer.moveToBottom(); // Move the background to the bottom layer
  //   stage.draw();
  
  //   // Export the canvas to a Data URL
  //   const uri = stage.toDataURL({ pixelRatio: pixelRatio });
  
  //   // Remove the temporary background
  //   layer.destroy();
  //   stage.draw();
  
  //   try {
  //     const blob = await fetch(uri).then((res) => res.blob());
  
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = async () => {
  //       const base64data = reader.result.split(',')[1];
  
  //       const formData = new FormData();
  //       formData.append('key', '22cc4babb6278d7a23eedbbc4acf37f7');  // Replace with your actual IMGBB API key
  //       formData.append('image', base64data);
  //       formData.append('expiration', 600);
  
  //       try {
  //         const response = await fetch("https://api.imgbb.com/1/upload", {
  //           method: "POST",
  //           body: formData,
  //         });
  
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         const responseData = await response.json();
  //         const imageUrl = responseData.data.url;
  //         changeURLFromCanvas(imageUrl)
  //         alert(`Image uploaded successfully! URL: ${imageUrl}`);
  //         hideCanvas()
          
  //       } catch (error) {
  //         console.error("Error during fetch:", error);
  //         alert("Failed to upload image. Please try again.");
  //       }
  //     };
  
  //     reader.onerror = (error) => {
  //       console.error("Error reading blob as base64:", error);
  //       alert("Failed to process image. Please try again.");
  //     };
  //   } catch (error) {
  //     console.error("Error fetching image blob:", error);
  //     alert("Failed to prepare image. Please try again.");
  //   }
  // };
  const handleSendToAPI = async () => {
    await setSelectedId(null)
    const stage = stageRef.current;
    const width = stage.attrs.width;
    const height = stage.attrs.height;
    const pixelRatio = 1024 / width;
  
    // Create a temporary layer to add a white background
    const layer = new Konva.Layer();
    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: width * pixelRatio,
      height: height * pixelRatio,
      fill: "white",
    });
    layer.add(background);
    stage.add(layer);
    layer.moveToBottom(); // Move the background to the bottom layer
    stage.draw();
  
    // Export the canvas to a Data URL
    const uri = stage.toDataURL({ pixelRatio: pixelRatio });
  
    // Remove the temporary background
    layer.destroy();
    stage.draw();
  
    try {
      const blob = await fetch(uri).then((res) => res.blob());
  
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1];
  
        const formData = new FormData();
        formData.append('key', '22cc4babb6278d7a23eedbbc4acf37f7');  // Replace with your actual IMGBB API key
        formData.append('image', base64data);
        formData.append('expiration', 600);
        console.log(base64data)
        try {
          const response = await fetch("https://api.imgbb.com/1/upload", {
            method: "POST",
            body: formData,
          });  
          // const response = await axios("https://api.imgbb.com/1/upload", {
          //   method: "POST",
          //   body: formData,
          // });  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const responseData = await response.json();
          const imageUrl = responseData.data.url;
          changeURLFromCanvas(imageUrl)
          alert(`Image uploaded successfully! URL: ${imageUrl}`);
          hideCanvas()
        } 
        catch (error) {
          console.error("Error during fetch:", error);
          alert("Failed to upload image. Please try again.");
        }
      };
  
      reader.onerror = (error) => {
        console.error("Error reading blob as base64:", error);
        alert("Failed to process image. Please try again.");
      };
    } catch (error) {
      console.error("Error fetching image blob:", error);
      alert("Failed to prepare image. Please try again.");
    }
  };
  const handleSendToAPI2 = async () => {
    const stage = stageRef.current;
    const width = stage.attrs.width;
    const height = stage.attrs.height;
    const pixelRatio = 1024 / width;

    // Create a temporary layer to add a white background
    const layer = new Konva.Layer();
    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: width * pixelRatio,
      height: height * pixelRatio,
      fill: "white",
    });
    layer.add(background);
    stage.add(layer);
    layer.moveToBottom(); // Move the background to the bottom layer
    stage.draw();

    // Export the canvas to a Data URL
    const uri = stage.toDataURL({ pixelRatio: pixelRatio });

    // Remove the temporary background
    layer.destroy();
    stage.draw();

    try {
      const blob = await fetch(uri).then((res) => res.blob());
      const file = new File([blob], `${Date.now()}.png`, { type: "image/png" });
      console.log(file)

      const formData = new FormData();
      formData.append('key', '22cc4babb6278d7a23eedbbc4acf37f7');  // Replace with your actual IMGBB API key
      //formData.append('image', base64data);
      formData.append('expiration', 600);
        
      // const response = await fetch("http://localhost:5000/image-upload", {
      //   method: "POST",
      //   body: formData,
      // });
      const  response= await imgbbUpload({
        key: "43853d449b56a2e477fd95dc2872c319", // your imgbb api key
        image: formData, // image selected from the file input
        expiration: 600, // 10 mins
        //name: shopName, // name of the image
      })
      

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert(response)
      const imageUrl = response.data.url;
      changeURLFromCanvas(imageUrl)
      hideCanvas()
      //alert(`Image uploaded successfully! URL: ${imageUrl}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    handlePresetImageUpload()
    return () => window.removeEventListener("resize", handleResize, false);
  }, []);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    const clickedOnBackground = e.target.getId() === "canvasBackground";
    if (clickedOnEmpty || clickedOnBackground) {
      setSelectedId(null);
    }
  };

  const onChangeDragUrl = (dragUrl) => {
    setDragUrl(dragUrl);
  };

  const handleTransformChange = (newAttrs, id) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, ...newAttrs } : item
    );
    setItems(updatedItems);
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    stageRef.current.setPointersPositions(e);
    const id = `image${items.length}`;
    setItems(
      items.concat([
        {
          ...stageRef.current.getPointerPosition(),
          src: dragUrl,
          id: id,
          type: "Image",
        },
      ])
    );
  };

  const handleAddOnClick = (src) => {
    let centerX = stageDimensions.width / 2;
    let centerY = stageDimensions.height / 2;
    const id = `image${items.length}`;
    setItems(
      items.concat([
        {
          x: centerX,
          y: centerY,
          src: src,
          id: id,
          type: "Image",
        },
      ])
    );
  };

  const addToBackground = (backgroundUrl) => {
    setBackgroundImage(backgroundUrl);
  };

  const removeBackground = () => {
    setBackgroundImage(null);
  };

  const resizeCanvasOnSidebarChange = () => {
    setTimeout(() => {
      handleResize();
    }, 420);
  };

  useEffect(() => {
    const handleDelete = (e) => {
      if (e.key === "Delete" && selectedId !== null) {
        const currentId = selectedId;
        setSelectedId(null);
        setItems(items.filter((item) => item.id !== currentId));
      }
    };
    window.addEventListener("keydown", handleDelete);
    return () => window.removeEventListener("keydown", handleDelete);
  }, [selectedId, items]);

  const layerUp = () => {
    if (selectedId === null) return;
    const index = items.findIndex((item) => item.id === selectedId);
    if (index < items.length - 1) {
      const newItems = [...items];
      const temp = newItems[index];
      newItems[index] = newItems[index + 1];
      newItems[index + 1] = temp;
      setItems(newItems);
      setSelectedId(newItems[index + 1].id);
    }
  };

  const layerDown = () => {
    if (selectedId === null) return;
    const index = items.findIndex((item) => item.id === selectedId);
    if (index > 0) {
      const newItems = [...items];
      const temp = newItems[index];
      newItems[index] = newItems[index - 1];
      newItems[index - 1] = temp;
      setItems(newItems);
      setSelectedId(newItems[index - 1].id);
    }
  };

  const bgRemove = async () => {
    if (selectedId === null) return;
    const selectedImage = items.find((item) => item.id === selectedId);
    const dataURL = selectedImage.src;
    try {
      const blob = await fetch(dataURL).then((res) => res.blob());
      const file = new File([blob], `${Date.now()}.png`, { type: "image/png" });

      const formData = new FormData();
      formData.append("file", file);

      // const response = await fetch(BASE_URL+"/image_query/bg_remove", {
      //   method: "POST",
      //   body: formData,
      // });

      const response = await fetch(BASE_URL+"/image_query/bg_remove", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData)
      const processedDataURL = `data:image/png;base64,${responseData.image}`;

      const updatedItems = items.map((item) =>
        item.id === selectedId ? { ...item, src: processedDataURL } : item
      );

      setItems(updatedItems);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const changeColor = (color) => {
    if (selectedId !== null && selectedId.startsWith("text")) {
      const updatedItems = items.map((item) =>
        item.id === selectedId ? { ...item, fill: color } : item
      );
      setItems(updatedItems);
    }
  };

  const handleBackgroundImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      addToBackground(imageUrl);
      e.target.value = ""; // Clear the input value
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      let centerX = stageDimensions.width / 2;
      let centerY = stageDimensions.height / 2;
      const id = `image${items.length}`;
      setItems(
        items.concat([
          {
            x: centerX,
            y: centerY,
            src: imageUrl,
            id: id,
            type: "Image",
          },
        ])
      );
      e.target.value = ""; // Clear the input value
    }
  };
  const handlePresetImageUpload = () => {
   
    const imageUrl = preset_image
    let centerX = stageDimensions.width / 2;
    let centerY = stageDimensions.height / 2;
    const id = `image${items.length}`;
    setItems(
      items.concat([
        {
          x: centerX,
          y: centerY,
          src: imageUrl,
          id: id,
          type: "Image",
        },
      ])
    );
   
  };

  const handleAddText = () => {
    let centerX = stageDimensions.width / 2;
    let centerY = stageDimensions.height / 2;
    const id = `text${items.length}`;
    setItems(
      items.concat([
        {
          x: centerX,
          y: centerY,
          text: "New Text",
          fontSize: 20,
          fontFamily: "Arial",
          draggable: true,
          width: 200,
          fill: "#000000",
          id: id,
          type: "Text",
        },
      ])
    );
  };

  return (
    <div className="workContainer relative min-h-[600px]">
      <div></div>

      <div className="canvasWrap">
        <div
          className="canvasBody"
          ref={containerRef}
          onDrop={handleOnDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Stage
            width={stageDimensions.width}
            height={stageDimensions.height}
            scaleX={stageDimensions.scale}
            scaleY={stageDimensions.scale}
            className="canvasStage"
            ref={stageRef}
            onMouseDown={(e) => {
              checkDeselect(e);
            }}
            onTap={(e) => {
              checkDeselect(e);
            }}
          >
            <Layer>
              {typeof backgroundImage === "string" && (
                <CanvasBackground
                  backgroundUrl={backgroundImage}
                  width={stageWidth}
                  height={stageHeight}
                />
              )}
              {items.map((item, i) => {
                if (item.type === "Image") {
                  return (
                    <ImageComponent
                      key={i}
                      image={item}
                      shapeProps={item}
                      isSelected={item.id === selectedId}
                      onSelect={() => {
                        setSelectedId(item.id);
                      }}
                      onChange={(newAttrs) => {
                        handleTransformChange(newAttrs, item.id);
                      }}
                    />
                  );
                } else if (item.type === "Text") {
                  return (
                    <TextComponent
                      key={i}
                      text={item.text}
                      shapeProps={item}
                      isSelected={item.id === selectedId}
                      onSelect={() => {
                        setSelectedId(item.id);
                      }}
                      onChange={(newAttrs) => {
                        handleTransformChange(newAttrs, item.id);
                      }}
                      onDeselect={() => setSelectedId(null)}
                    />
                  );
                }
                return null;
              })}
            </Layer>
          </Stage>
        </div>
        {selectedId !== null && selectedId.startsWith("image") ? (
          <ImageEditToolbar
            layerUp={layerUp}
            layerDown={layerDown}
            bgRemove={bgRemove}
            removeImage={() => {
              const currentId = selectedId;
              setSelectedId(null);
              setItems(items.filter((item) => item.id !== currentId));
            }}
          />
        ) : (
          selectedId !== null &&
          selectedId.startsWith("text") && (
            <TextEditToolbar
              layerUp={layerUp}
              layerDown={layerDown}
              fontSize={items.find((item) => item.id === selectedId).fontSize}
              onIncreaseFontSize={handleIncreaseFontSize}
              onDecreaseFontSize={handleDecreaseFontSize}
              fontFamily={
                items.find((item) => item.id === selectedId).fontFamily
              }
              onFontFamilyChange={handleFontFamilyChange}
              removeText={() => {
                const currentId = selectedId;
                setSelectedId(null);
                setItems(items.filter((item) => item.id !== currentId));
              }}
              changeColor={changeColor}
            />
          )
        )}
      </div>
      <div
        onClick={() => setShowMainOptions(!showMainOptions)}
        className="absolute bottom-4 right-4 z-10 p-4 rounded-full bg-primary-500 hover:bg-primary-600 cursor-pointer text-white"
      >
        {!showMainOptions && <Edit />}
        {showMainOptions && <Close />}
      </div>
      <div
        onClick={handleSendToAPI}
        className="absolute bottom-4 left-4 z-10 p-4 rounded-full bg-primary-500 hover:bg-primary-600 cursor-pointer text-white"
      >
        <Save/>
      </div>
      <div
        onClick={hideCanvas}
        className="absolute top-2 left-4 z-10 p-1 md:p-4 rounded-full bg-gray-500 hover:bg-gray-600 cursor-pointer text-white"
      >
        <Close />
      </div>
      {showMainOptions && (
        <div className="flex flex-col absolute bottom-24 right-4 bg-white">
          <div className="bottomButtons">
            <button
              className="px-4 py-2 my-1 rounded bg-gray-200 hover:bg-gray-400 hover:text-white text-left text-xs font-semibold"
              onClick={handleAddText}
            >
              Add Text
            </button>

            <button
              className="px-4 py-2 my-1 rounded bg-gray-200 hover:bg-gray-400 hover:text-white text-left text-xs font-semibold"
              onClick={() => document.getElementById("normal-image").click()}
            >
              Add Image
            </button>
            <input
              id="normal-image"
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
            />
            <button
              className="px-4 py-2 my-1 rounded bg-gray-200 hover:bg-gray-400 hover:text-white text-left text-xs font-semibold"
              onClick={() =>
                document.getElementById("background-image").click()
              }
            >
              Add Background
            </button>
            <input
              id="background-image"
              type="file"
              onChange={handleBackgroundImageUpload}
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
            />
            <button
              className="px-4 py-2 my-1 rounded bg-gray-200 hover:bg-gray-400 hover:text-white text-left text-xs font-semibold"
              onClick={removeBackground}
            >
              Remove Background
            </button>
            <div className="saveButtons">
              <button
                className="px-4 py-2 my-1 rounded bg-gray-200 hover:bg-gray-400 hover:text-white text-left text-xs font-semibold"
                onClick={handleDownloadImage}
              >
                Save Image
              </button>
       
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Canvas;
