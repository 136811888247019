import axios from 'axios';
import { BASE_URL } from '../Data/apiData';
const API_URL = BASE_URL+'/shop_users';

// Function to get a shop user by ID
async function getShopUser(shopUserId) {
  try {
    const response = await axios.get(`${API_URL}/${shopUserId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching shop user:", error.response ? error.response.data : error.message);
    throw error;
  }
}

// Function to create a new shop user
async function createShopUser(shopUserData) {
  try {
    const response = await axios.post(API_URL, {
      shop_id: shopUserData.shop_id,
      user_type: shopUserData.user_type,
      user_name: shopUserData.user_name,
      user_phone: shopUserData.user_phone,
      user_image: shopUserData.user_image,
      user_email: shopUserData.user_email
    });
    return response.data;
  } catch (error) {
    console.error("Error creating shop user:", error.response ? error.response.data : error.message);
    throw error;
  }
}

// Function to update an existing shop user
async function updateShopUser(shopUserId, shopUserData) {
  try {
    const response = await axios.put(`${API_URL}/${shopUserId}`, {
      shop_id: shopUserData.shop_id,
      user_type: shopUserData.user_type,
      user_name: shopUserData.user_name,
      user_phone: shopUserData.user_phone,
      user_image: shopUserData.user_image
    });
    return response.data;
  } catch (error) {
    console.error("Error updating shop user:", error.response ? error.response.data : error.message);
    throw error;
  }
}

// Function to delete a shop user by ID
async function deleteShopUser(shopUserId) {
  try {
    const response = await axios.delete(`${API_URL}/${shopUserId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting shop user:", error.response ? error.response.data : error.message);
    throw error;
  }
}

// Function to get all shop users for a specific shop
async function getShopUsersForShop(shopId) {
  try {
    const response = await axios.get(`${API_URL}/shop/${shopId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching shop users for shop:", error.response ? error.response.data : error.message);
    throw error;
  }
}

//Shop user along with shop using email
async function getShopUserAlongWithShopUsingEmail(email){
  try {
    const response = await axios.get(`${API_URL}/user_with_shop/${email}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching shop user:", error.response ? error.response.data : error.message);
    throw error;
  }
}


// Example usage:

//Shop user along with shop using email

// getShopUserAlongWithShopUsingEmail("ss@example.com")
//   .then(data => console.log("Data:", data))
//   .catch(error => console.error("Error:", error));

// Get a shop user by ID
// getShopUser('37a11d85-196a-4355-a127-c9e549983605')
//   .then(data => console.log("Shop user data:", data))
//   .catch(error => console.error("Error:", error));

// Create a new shop user
// const newShopUser = {
//   shop_id: '52cb581c-3cdb-4576-9145-3b53693c5570',
//   user_type: 'employee',
//   user_name: 'Nazmus Sakib',
//   user_phone: '+1234567890',
//   user_image: 'https://example.com/image.png',
//   user_email: 'ns@example.com',
// };
// createShopUser(newShopUser)
//   .then(data => console.log("Created shop user:", data))
//   .catch(error => console.error("Error:", error));

// Update an existing shop user
// const updatedShopUser = {
//   shop_id: 'fb892154-e645-47ea-80cd-0d3dc510623d',
//   user_name: 'Admin',
//   user_type: 'admin'
// };
// updateShopUser('37a11d85-196a-4355-a127-c9e549983605', updatedShopUser)
//   .then(data => console.log("Updated shop user:", data))
//   .catch(error => console.error("Error:", error));

// Delete a shop user by ID
// deleteShopUser('13bd7caa-276a-4940-9a8c-a1eab4d18766')
//   .then(data => console.log("Deleted shop user:", data))
//   .catch(error => console.error("Error:", error));

// Get all shop users for a specific shop
// getShopUsersForShop('fb892154-e645-47ea-80cd-0d3dc510623d')
//   .then(data => console.log("Shop users for shop:", data))
//   .catch(error => console.error("Error:", error));

export { getShopUser,getShopUserAlongWithShopUsingEmail, createShopUser, updateShopUser, deleteShopUser, getShopUsersForShop };
