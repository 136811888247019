import Navbar from '../../../Components/Navbar'
import React,{useEffect, useState} from 'react'
import { PiSpinnerLight } from "react-icons/pi";
import {RiUploadCloud2Line} from 'react-icons/ri'
import axios from "axios"
import imgbbUpload from "imgbb-image-uploader";
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Data/apiData';
import { getShop, updateShop } from '../../../API/shops';
import SideNavbarWide from '../../../Components/SideNavbarWide';

export default function EditDetails(){
    const navigator=useNavigate()

    const [updating,setUpdating]=useState(false)
    const [loading,setLoading]=useState(true)

    const [shopImage,setShopImage]=useState(null)
    const [shopName,setShopName]=useState(null)
    const [shopType,setShopType]=useState(null)
    const [shopPhone,setShopPhone]=useState(null)
    const [shopEmail,setShopEmail]=useState(null)
    const [shopAddress,setShopAddress]=useState(null)
    const [shopBio,setShopBio]=useState("")

    const [uploadingImage,setUploadingImage]=useState(false)
    const [imageUploaded,setImageUploaded]=useState(false)
    const [changeImage,setChangeImage]=useState(false)
    const imageUpload=async(file)=>{
        setUploadingImage(true)
        const  res= await imgbbUpload({
            key: "43853d449b56a2e477fd95dc2872c319", // your imgbb api key
            image: file, // image selected from the file input
            //expiration: 600, // 10 mins
            //name: "name-of-the-image", // name of the image
            })
        setUploadingImage(false)
        setImageUploaded(true)
        setShopImage(res.data["display_url"])
    }
    const getData=async()=>{
            const res= await getShop(localStorage.getItem("bebsha-store"))
            console.log(res)
            setShopImage(res.image)
            setShopName(res.name)
            setShopType(res.type)
            setShopPhone(res.phones[0])
            setShopEmail(res.email)
            setShopAddress(res.address)
            setShopBio(res.bio)
            setChangeImage(false)
            setLoading(false)
        }

        const updateTheming=async()=>{
            setUpdating(true)
            const reqBody={
            name: shopName,
            image: shopImage,
            address:shopAddress,
            type: shopType,
            phones: [shopPhone],
            email: shopEmail,
            bio: shopBio
            }
            console.log(reqBody)
            const res = await updateShop(localStorage.getItem("bebsha-store"),reqBody)
            setUpdating(false)
            if(res.status==201){
                alert("Data Updated!")
            }
            console.log(res)
        }
        useEffect(()=>{
            getData()
        },[])
        if(loading){
            return(<div className='w-screen h-screen flex items-center justify-center'>
                Loading Data..
            </div>)
        }
    return (
        <div className='w-screen min-h-screen max-w-screen flex flex-col bg-gray-100 items-center justify-center'>    
            <SideNavbarWide/> 
            <div className='min-h-screen m-4 mt-20'>
                <div className='w-full md:max-w-xl my-4 p-6 shadow-lg bg-white flex flex-col md:flex-row items-start justify-center rounded-xl md:ml-24'>
                {/** Category Image Upload */}
                <div className="max-w-xl mx-auto">
                    <label  className="block text-sm font-medium text-gray-900 dark:text-white">Shop Image Upload</label>
                    <div className='max-w-xl mx-auto min-h-sm flex flex-col items-center rounded-lg border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 p-4'>
                        {changeImage && !imageUploaded && !uploadingImage && <div>
                            <div className="flex items-center justify-center h-full w-full ">
                                <label className="flex flex-col items-center justify-center w-full h-2/3 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <RiUploadCloud2Line size={32} className="text-gray-500 dark:text-gray-400"/>
                                </div>
                                <input type="file" className="hidden" onChange={e=>{imageUpload(e.target.files[0])}}/>
                                </label>
                            </div>
                            <p className='text-sm text-center text-gray-400'>Upload a shop image. The recommended size is 500x500. Maximum file size 4MB</p>
                        </div>
                        }
                        {uploadingImage && <div>Uploding Image...</div>}
                        {(imageUploaded || !changeImage) && <img src={shopImage} className='w-[200px]'/>}
                        {(!changeImage) && <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={()=>setChangeImage(true)}  className="text-white w-full my-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Change Image</motion.button>}
                        
                    </div>
                </div>
                
                <div className='bg-white dark:bg-gray-900 ' >
                    
                    
                        {/** Text Inputs */}
                        <div className="w-full mx-auto">
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shop Name</label>
                            <input value={shopName} onChange={(e)=>setShopName(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shop Type</label>
                            <input value={shopType} onChange={(e)=>setShopType(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                            <input value={shopPhone} onChange={(e)=>setShopPhone(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address(For Shop)</label>
                            <input value={shopEmail} onChange={(e)=>setShopEmail(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shop Address</label>
                            <input value={shopAddress} onChange={(e)=>setShopAddress(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shop Bio</label>
                            <textarea value={shopBio} onChange={(e)=>setShopBio(e.target.value)} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write a small bio for your shop..."></textarea>
                        </div>
                            
                            <div className='w-full flex items-center justify-between'>
                                <button onClick={updateTheming} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{updating?<p className='flex items-center'> <PiSpinnerLight className='text-lg'/> Updating Shop Details..</p>:<p>Update Shop Details</p>}</button>
                            </div>
                            
                            </div>
                    </div>
                    
                </div>
            </div>
        </div>
)}


