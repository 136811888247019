import React from 'react'
import Decimal from "decimal.js";
import {motion} from 'framer-motion'
import { RiDeleteBin6Line } from "react-icons/ri";

export default function OrderCard({products,product,updateQuantity,removeProduct}) {
  return (
    <div key={product.id} className="flex justify-between my-4">
        <div className="flex flex-1 px-2">
            <div className='flex flex-col'>
                <h3 className="text-md font-semibold">{products.find((p) => p.id === product.id).name}</h3>
                <p className='text-xs font-semibold text-indigo-500'>Unit : ৳{new Decimal(product.unit_price).toFixed(2).toString()}</p>
            </div>
            
        </div>
        <div className="flex-1 flex items-center justify-center">
            <button onClick={() => updateQuantity(product.id, -1)} className="bg-gray-100 border border-gray-100 px-3 py-1 rounded-l-md hover:bg-gray-200">-</button>
            <span className="bg-white border border-gray-100 p-2 text-xs font-semibold">{product.quantity}</span>
            <button onClick={() => updateQuantity(product.id, 1)} className="bg-gray-100 border border-gray-100 px-3 py-1 rounded-r-md hover:bg-gray-200">+</button>
            <button onClick={() => removeProduct(product.id)} className="bg-gray-100 p-2 mx-2 rounded-md hover:bg-gray-200">
                <RiDeleteBin6Line/>
            </button>
        </div>
        <div className="flex-1 flex items-center justify-end">
            <p className="bg-white text-sm">৳{new Decimal(product.unit_price*product.quantity).toFixed(2)}</p>
        </div>
   
    </div>
  )
}
