import React from 'react'
import Decimal from "decimal.js";
import {motion} from 'framer-motion'
export default function ItemCard({product,addToOrderFunc}) {
  return (
    <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} onClick={() => addToOrderFunc(product)} key={product.id} className="border bg-white border-gray-100 rounded-lg shadow-lg p-4 cursor-pointer hover:bg-gray-100">
        <img className="w-full h-48 object-contain mb-4 rounded" src={product.images[0]} alt={product.name} />
        <div className='flex flex-col items-center textcenter'> 
            <h2 className="text-xl font-normal ">{product.name}</h2>
            <p className="text-sm text-gray-700 mb-2"><span className='font-bold text-indigo-500'>৳{new Decimal(product.current_price).toFixed(2).toString()}</span></p>
        </div>
    </motion.button>
  )
}
