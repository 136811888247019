// OrdersPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import data from "./data.json";
import { MdOutlineCancel,MdDone } from "react-icons/md";
import {motion} from 'framer-motion'
import { FaAngleRight } from "react-icons/fa6";
import SideNavbar from "../../Components/SideNavbar";
import { useNavigate } from "react-router-dom";
import SideNavbarShort from "../../Components/SideNavbarShort";
import { BASE_URL } from "../../Data/apiData";
const OrdersPage = () => {
  const navigator=useNavigate()
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const server_url = BASE_URL
  const shop_id = localStorage.getItem("bebsha-store")
  async function fetchOrders() {
    setLoading(true)
    try {
      const response = await axios.get(
        `${server_url}/orders/shop/${shop_id}/`
      );
      setOrders(response.data);
      setLoading(false);
      console.log(response.data); // Log the response to debug
    } catch (error) {
      console.error(
        "Error fetching orders:",
        error.response ? error.response.data : error.message
      );
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  function msToTime(s) {

    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60-6;
    if(hrs>0){
      return hrs + " hours ago"
    }
    if(mins>0){
      return mins + " mins ago"
    }
    else{
      return "Just a few moments ago"
    }
  }
  const updateOrderStatus = async (orderId, status) => {
    try {
      const response = await axios.put(`${server_url}/orders/${orderId}`, {
        status,
      });
      console.log(response.data); // Log the response to debug
      // Refresh orders list
      //   fetchOrders();

      // Remove the order from the list if the update is successful
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.id !== orderId)
      );
    } catch (error) {
      console.error(
        "Error updating order:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-100 min-h-screen pl-20 md:pl-24">
      <SideNavbarShort/>
      <h1 className="text-3xl font-semibold pt-6 text-left text-primary-500">Orders List</h1>
      <h6 className="text-sm mb-4 text-left text-gray-800">View all your orders </h6>
      <ol class="inline-flex items-center space-x-1 md:space-x-2 my-4">
        <li class="inline-flex items-center"><button onClick={()=>navigator("/")} class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-white">Dashboard</button></li>
        <li><div class="flex items-center"><FaAngleRight/><button onClick={()=>navigator("/pos")} class="ms-1 text-sm font-medium text-gray-700 hover:text-indigo-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">POS</button></div></li>
        <li><div class="flex items-center"><FaAngleRight/><span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Order List</span></div></li>
      </ol>
      {/* {orders.length === 0 ? (
        <div className="text-lg text-center text-gray-600">
          No pending orders
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {orders.map((order) => (
            <div
              key={order.id}
              className="border rounded-lg shadow-lg p-6 bg-white flex flex-col justify-between"
            >
              <div>
                <h2 className="text-xl font-semibold mb-2 text-indigo-600">
                  Order ID: {order.id}
                </h2>
                <p className="text-lg mb-2">
                  Total Amount:{" "}
                  <span className="font-bold text-green-600">
                    ৳{order.total_amount}
                  </span>
                </p>
                <p
                  className={`text-lg mb-4 font-bold ${
                    order.status === "pending" ? "text-yellow-500" : ""
                  }`}
                >
                  Status:{" "}
                  {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                </p>
                <div>
                  <h3 className="font-semibold mb-2">Order Items:</h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                            Image
                          </th>
                          <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                            Product
                          </th>
                          <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                            Quantity
                          </th>
                          <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                            Unit Price
                          </th>
                          <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.order_items.map((item) => (
                          <tr key={item.id}>
                            <td className="py-2 px-4 border-b border-gray-200">
                              <img
                                src={item.product.images[0]}
                                alt={item.product.name}
                                className="w-12 h-12 object-cover rounded"
                              />
                            </td>
                            <td className="py-2 px-4 border-b border-gray-200">
                              {item.product.name}
                            </td>
                            <td className="py-2 px-4 border-b border-gray-200">
                              {item.quantity}
                            </td>
                            <td className="py-2 px-4 border-b border-gray-200">
                              ৳{item.unit_price}
                            </td>
                            <td className="py-2 px-4 border-b border-gray-200">
                              ৳{item.total_price}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => updateOrderStatus(order.id, "cancelled")}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                >
                  Cancel
                </button>
                <button
                  onClick={() => updateOrderStatus(order.id, "completed")}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                >
                  Complete
                </button>
              </div>
            </div>
          ))}
        </div>
      )} */}
      <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right  dark:text-gray-400 border border-gray-200  shadow-lg rounded-lg">
              <thead class="text-md text-white  bg-indigo-500 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-6">
                          OrderID
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Status
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Total Amount
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Order Time
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Actions
                      </th>
                      
                  </tr>
              </thead>
              <tbody>
                {orders.map(order=>(
                  <tr class="flex-col bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td scope="row" class="px-6 py-4 underline hover:text-indigo-500 hover:font-semibold cursor-pointer dark:text-white">
                          {order.id}
                      </td>
                      <td class="px-6 py-4 text-xs">
                          {order.status=="pending" && <p className="max-w-[100px] flex justify-center px-4 py-2 rounded-lg text-xs text-white bg-amber-500 font-semibold">PENDING</p>}
                          {order.status=="cancelled" && <p className="max-w-[100px] flex justify-center px-4 py-2 rounded-lg text-xs text-white bg-red-500 font-semibold">CANCELLED</p>}
                          {order.status=="completed" && <p className="max-w-[100px] flex justify-center px-4 py-2 rounded-lg text-xs text-white bg-green-500 font-semibold">COMPLETED</p>}
                           {/* {order.status=="completed" && <p className="flex px-4 py-2 rounded-lg text-xs text-white bg-green-500 font-semibold"><div className="w-2 h-2 mr-2 bg-white rounded-full"></div>COMPLETED</p>}
                         {order.status=="cancelled" && <span className="px-4 py-2 bg-red-500 rounded-lg text-xs text-white font-semibold">CANCELLED</span>}
                          {order.status=="completed" && <span className="px-4 py-2 bg-green-500 rounded-lg text-xs text-white font-semibold">COMPLETED</span>} */}
                      </td>
                      <td class="px-6 py-4 font-semibold">
                        ৳{order.total_amount}
                      </td>
                      <td class="px-6 py-4 text-xs flex flex-col">
                      
                          {msToTime(Date.now()-new Date(order.created_at))}
                      
                        {/* <span className="text-sm font-semibold">
                          {new Date(order.created_at).getUTCHours()<10?"0":''}{new Date(order.created_at).getUTCHours()} : {new Date(order.created_at).getUTCMinutes()<10?"0":''}{new Date(order.created_at).getUTCMinutes()}
                        </span>
                        <span>
                          {new Date(order.created_at).getDate()}-{new Date(order.created_at).getMonth()}-{new Date(order.created_at).getFullYear()}
                        </span> */}
                        
                        
                        
                        
                      </td>
                      
                      <td class="px-6 py-4">
                          {order.status=="pending" && <div>
                            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} onClick={()=>{
                              updateOrderStatus(order.id, "cancelled")
                              fetchOrders()
                            }} className="mx-1 p-1 text-xl font-bold bg-red-500 rounded-full text-white"><MdOutlineCancel/></motion.button>
                            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} onClick={()=>{
                              updateOrderStatus(order.id, "completed")
                              fetchOrders()
                            }} className="mx-1 p-1 text-xl font-bold bg-green-500 rounded-full text-white"><MdDone/></motion.button>
                          </div>}
                      </td>
                     
                      
                  </tr>
                ))}
                  
                  
              </tbody>
          </table>
      </div>






    </div>
  );
};

export default OrdersPage;
