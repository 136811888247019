import React, { useState } from "react";
import Select from "react-select";
import axios from 'axios'
import { BASE_URL } from "../../Data/apiData";
import { MdClose, MdDelete } from "react-icons/md";
import { CircularProgress } from "@mui/material";
async function deleteProduct(productId) {
  try {
    const response = await axios.delete(`${BASE_URL}/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting product:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}
export default function EditProductModal({ product, categories, onSave, onCancel }) {
    const [formData, setFormData] = useState({ ...product });
    const [deleting, setDeleting] = useState(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Prevent negative values for numeric fields
      if (
        ["current_price", "previous_price", "quantity"].includes(name) &&
        value < 0
      ) {
        return;
      }
  
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleCategoryChange = (selectedOptions) => {
      setFormData({
        ...formData,
        shop_category_id: selectedOptions.map((option) => option.value),
      });
    };
  
    const categoryOptions = Object.entries(categories).map(([id, name]) => ({
      value: id,
      label: name,
    }));
  
    const handleSave = async () => {
      await onSave(formData);
    };
    const handleDelete=async()=>{
      setDeleting(true)
      await deleteProduct(product.id)
      window.location.reload()
    }
    if(deleting){
      return (
        <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="flex items-center bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto">
            <CircularProgress/> <p className="ml-2 text-lg font-semibold">Deleting product listing....</p>
          </div>
        </div>
    )
    }
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        
        <div className="relative bg-white m-4 p-8 rounded-lg shadow-lg max-w-3xl">
        <button onClick={onCancel} className="absolute top-2 right-2 "><MdClose className="hover:color-gray-500"/></button>
          <h2 className="text-2xl font-bold mb-6">Edit Product</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Product Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Product Name"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Product Code
              </label>
              <input
                type="text"
                name="product_code"
                value={formData.product_code}
                onChange={handleChange}
                placeholder="Product Code"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Description
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-medium mb-2">
                  Current Price
                </label>
                <input
                  type="number"
                  name="current_price"
                  value={formData.current_price}
                  onChange={handleChange}
                  placeholder="Current Price"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium mb-2">
                  Previous Price
                </label>
                <input
                  type="number"
                  name="previous_price"
                  value={formData.previous_price}
                  onChange={handleChange}
                  placeholder="Previous Price"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Warranty
              </label>
              <input
                type="text"
                name="warranty"
                value={formData.warranty}
                onChange={handleChange}
                placeholder="Warranty"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Shop Category
              </label>
              <Select
                isMulti
                name="shop_category_id"
                value={categoryOptions.filter((option) =>
                  formData.shop_category_id.includes(option.value)
                )}
                onChange={handleCategoryChange}
                options={categoryOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <label className="flex items-center space-x-3 mt-4">
              <input
                type="checkbox"
                name="active"
                checked={formData.active || false}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.checked })
                }
                className="form-checkbox"
              />
              <span className="text-gray-700 font-medium">Available</span>
            </label>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            <button
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-green-500 hover:green-600 text-white px-4 py-2 rounded-lg"
            >
              Save
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
            >
              <MdDelete/>
            </button>
          </div>
        </div>
      </div>
    );
  }