// src/pages/ProductListing.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../Data/apiData";
import SideNavbarShort from "../../Components/SideNavbarShort";

async function getShopCategoriesForShop(shopId) {
  try {
    const response = await axios.get(
      `${BASE_URL}/shop_categories/shop/${shopId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching shop categories:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

async function createProduct(productData) {
  console.log(JSON.stringify(productData));
  try {
    const response = await axios.post(`${BASE_URL}/products`, productData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating product:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}


function ProductListingFree() {
  //const shopId = "52cb581c-3cdb-4576-9145-3b53693c5570";
  const shopId=localStorage.getItem("bebsha-store")
  const [categories, setCategories] = useState([]);
  const [feedback, setFeedback] = useState({ type: "", message: "" });

  const [productData, setProductData] = useState({
    shop_id: shopId,
    shop_category_id: [],
    sub_category_id: null,
    image: null,
    name: "",
    previous_price: null,
    current_price: 0,
    product_code: "",
    quantity: 0,
    warranty: "",
    description: "",
    active: false,
  });

  useEffect(() => {
    async function fetchCategories() {
      try {
        const categories = await getShopCategoriesForShop(shopId);
        setCategories(
          categories.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategories();
  }, [shopId]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setProductData({
      ...productData,
      [name]: type === "number" && value < 0 ? 0 : value,
    });
  };

  const handleFileChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setProductData({
      ...productData,
      shop_category_id: selectedOptions.map((option) => option.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Upload the image to imgbb
      const formData = new FormData();
      formData.append("key", "22cc4babb6278d7a23eedbbc4acf37f7"); // Replace with your actual IMGBB API key
      formData.append("image", productData.image);
      formData.append("expiration", 600);

      const response = await fetch("https://api.imgbb.com/1/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      const imageUrl = responseData.data.url;

      // Update productData with the image URL
      const updatedProductData = {
        ...productData,
        images: [imageUrl],
      };

      // Create the product with the updated data
      await createProduct(updatedProductData);
      setFeedback({
        type: "success",
        message: "Product created successfully!",
      });
    } catch (error) {
      console.error("Error during fetch:", error);
      setFeedback({ type: "error", message: "Failed to create product." });
    }
  };

  return (
    <div className="w-screen min-h-screen p-8 pl-24 flex flex-col items-start justify-start">
      <SideNavbarShort/>
      <h1 className="text-sm mt-12 md:mt-0 md:text-3xl font-semibold mb-6 px-2">List New Product</h1>
      <div className="p-8 max-w-2xl mx-auto bg-blue-300">
        {/* {feedback.message && (
          <div
            className={`mb-4 text-center ${
              feedback.type === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {feedback.message}
          </div>
        )} */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-lg font-medium">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={productData.name}
              onChange={handleInputChange}
              required
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Category </label>
            <Select isMulti options={categories} onChange={handleSelectChange} />
          </div>
          {/* <div>
            <label className="block text-lg font-medium">Sub Category</label>
            <input
              type="text"
              name="sub_category_id"
              value={productData.sub_category_id || ''}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div> */}
          <div>
            <label className="block text-lg font-medium">
              Image <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              required
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Previous Price</label>
            <input
              type="number"
              name="previous_price"
              value={productData.previous_price || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">
              Current Price <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="current_price"
              value={productData.current_price}
              onChange={handleInputChange}
              required
              className="border rounded px-3 py-2 w-full"
              min="0"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Product Code</label>
            <input
              type="text"
              name="product_code"
              value={productData.product_code || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Quantity</label>
            <input
              type="number"
              name="quantity"
              value={productData.quantity || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              min="0"
              step="1"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Warranty</label>
            <input
              type="text"
              name="warranty"
              value={productData.warranty || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label className="block text-lg font-medium">Description</label>
            <textarea
              name="description"
              value={productData.description || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            ></textarea>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="active"
              checked={productData.active}
              onChange={() =>
                setProductData({ ...productData, active: !productData.active })
              }
              className="mr-2"
            />
            <label className="text-lg font-medium">Active</label>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProductListingFree;
