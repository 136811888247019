import React, { useRef, useEffect, useState } from "react";
import { Text, Transformer } from "react-konva";

const TextComponent = ({
  text,
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onDeselect,
}) => {
  const shapeRef = useRef();
  const transformRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(text);
  const inputRef = useRef();

  useEffect(() => {
    setTextValue(text); // Update textValue when the text prop changes
  }, [text]);

  useEffect(() => {
    if (isSelected) {
      transformRef.current.nodes([shapeRef.current]);
      transformRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    if (isEditing) {
      const textNode = shapeRef.current;

      if (!textNode) return;
      textNode.hide();

      const stage = shapeRef.current.getStage();
      const { x, y, width, height } = shapeRef.current.getClientRect({
        relativeTo: stage,
      });

      const input = document.createElement("textarea");
      stage.container().appendChild(input);

      const textPosition = textNode.absolutePosition();
      const containerRect = stage.container().getBoundingClientRect();
      const areaPosition = {
        x: stage.container().offsetLeft + textPosition.x,
        y: stage.container().offsetTop + textPosition.y,
      };

      const ratio = stage.attrs.width / 1024;

      input.value = textValue;
      input.style.position = "absolute";
      input.style.top = areaPosition.y + "px";
      input.style.left = areaPosition.x + "px";
      input.style.width = textNode.width();
      input.style.height = textNode.height();
      input.style.fontSize = `${textNode.fontSize() * ratio}px`;
      input.style.fontFamily = textNode.fontFamily();
      input.style.textAlign = textNode.align();
      input.style.color = textNode.fill();
      input.style.background = "none";
      input.style.border = "none";
      input.style.padding = 0;
      input.style.margin = 0;
      input.style.overflow = "hidden";
      input.style.outline = "none";
      input.style.transform = `rotateZ(${shapeRef.current.rotation()}deg)`;
      input.style.lineHeight = textNode.lineHeight();
      input.style.resize = "none";
      input.style.whiteSpace = "pre-wrap"; // Allow wrapping of text
      input.style.wordWrap = "break-word"; // Break words when needed

      input.focus();

      const adjustTextareaSize = () => {
        input.style.height = "auto";
        input.style.height = `${input.scrollHeight}px`;
        input.style.width = "auto";
        input.style.width = `${input.scrollWidth}px`;
      };

      adjustTextareaSize();

      const handleBlur = () => {
        setIsEditing(false);
        onChange({ ...shapeProps, text: input.value });
        if (input.parentNode) {
          input.parentNode.removeChild(input);
        }
        textNode.show();
      };

      const handleInput = (e) => {
        setTextValue(e.target.value);
        onChange({ ...shapeProps, text: e.target.value });
        adjustTextareaSize();
      };

      const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleBlur();
        }
        if (e.key === "Escape") {
          handleBlur();
        }
      };

      input.addEventListener("blur", handleBlur);
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);

      inputRef.current = input;

      return () => {
        input.removeEventListener("blur", handleBlur);
        input.removeEventListener("input", handleInput);
        input.removeEventListener("keydown", handleKeyDown);
        if (input.parentNode) {
          input.parentNode.removeChild(input);
        }
      };
    }
  }, [isEditing, textValue, onChange, shapeProps]);

  const handleTransform = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Apply the new width and height
    node.width(Math.max(30, node.width() * scaleX));
    node.height(Math.max(30, node.height() * scaleY));

    // Reset the scale to avoid scaling effect
    node.scaleX(1);
    node.scaleY(1);
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    onChange({
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    });
  };

  const handleDblClick = () => {
    setIsEditing(true);
  };

  const handleDblTap = () => {
    setIsEditing(true);
  };

  return (
    <>
      <Text
        text={textValue}
        x={shapeProps.x}
        y={shapeProps.y}
        fill={shapeProps.fill}
        fontSize={shapeProps.fontSize}
        fontFamily={shapeProps.fontFamily} // Pass the fontFamily property
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onMouseDown={onSelect}
        onTransform={handleTransform}
        onTransformEnd={handleTransformEnd}
        onDblClick={handleDblClick}
        onDblTap={handleDblTap}
        ref={shapeRef}
        {...shapeProps}
        onClick={onSelect}
        onTap={onSelect}
      />
      {isSelected && (
        <Transformer
          ref={transformRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 30) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TextComponent;
