import React from 'react'

const Card = ({icon,title}) => {
  return (
    <div href="#" class="flex flex-col items-center justify-center max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 cursor-pointer dark:hover:bg-gray-600">
        <div className="text-white p-2 rounded-md bg-blue-500">{icon}</div>
        <p class="font-normal text-gray-700 dark:text-gray-400">{title}</p>
    </div>
  )
}

export default Card
