import React,{useEffect, useState} from 'react'
import Navbar from '../../Components/Navbar'
import { BiEditAlt } from 'react-icons/bi'
import {motion} from 'framer-motion'
import { CgAdd } from 'react-icons/cg'
import {  MdOutlineDone  } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../Data/apiData'
import { CircularProgress } from '@mui/material'
export default function ShopEditor() {
    const navigator=useNavigate()
    const [editPhoto,setEditPhoto]=useState(false)
    const [editName,setEditName]=useState(false)
    const [editCategory,setEditCategory]=useState(-1)
    const [addCategory,setAddCategory]=useState(false)
    const [newCatName,setNewCatName]=useState(null)
    const [newName,setNewName]=useState(null)
    const [catName,setCatName]=useState(null)
    const [loading,setLoading]=useState(true)
    const [data,setData]=useState(null)
    const [categories,setCategories]=useState([])
    const addNewCategory=async()=>{
        if(localStorage.getItem("bebsha-store")){
            const res=await axios.post(`${BASE_URL}/category/`,{
                "name": newCatName,
                "image":"url",
                "shop_id": localStorage.getItem("bebsha-store") 
            })
            console.log(res)
        }
        setNewCatName(null)
        setAddCategory(false)
        getShopDetails()
    }
    const updateShopName=async()=>{
        if(localStorage.getItem("bebsha-store")){
            const res=await axios.put(`${BASE_URL}/shop-details/`,{
                "id": localStorage.getItem("bebsha-store") ,
                "name": newName
                
            })
            console.log(res)
        }
        setEditName(false)
        getShopDetails()
    }
    const getShopDetails=async()=>{
        setLoading(true)
        if(localStorage.getItem("bebsha-store")){
            const res=await axios.get(`${BASE_URL}/shop/${localStorage.getItem("bebsha-store")}`)
            setData(res.data)
            const catRes=await axios.get(`${BASE_URL}/categories/${localStorage.getItem("bebsha-store")}`)
            console.log(catRes.data)
            if(catRes.data.length>0){
               setCategories(catRes.data)
            }
           
            setLoading(false)
        }
    }
    useEffect(()=>{
        getShopDetails()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <CircularProgress className='mr-2'/> Loading Data..
        </div>)
    }

  return (
    <div >
        <Navbar/>
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            <div className='w-32 h-32 rounded-full p-4 border border-gray-200 hover:bg-gray-50 shadow-lg  flex items-center justify-center cursor-pointer'>
                 <img className='w-24' alt="im" src={data.image}/>
                
            </div>
            <div className='w-full max-w-3xl mt-8'>
                <label>Store Name</label>
                <hr/>
                {!editName && <div className='flex items-center justify-between mt-2'>
                    <h2 className='text-2xl font-semibold'>{data.name}</h2>
                    <div onClick={()=>setEditName(true)} className='p-2 rounded-full hover:bg-primary-500 hover:text-white cursor-pointer'><BiEditAlt  size={24}/></div>
                </div>}
                {editName && <div className="flex mb-4">
                    <input value={newName} onChange={(e)=>setNewName(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ps-10 px-6 py-4  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="New Store Name" required />
                    <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={updateShopName} className='ml-2 flex p-4 text-sm items-center rounded-xl bg-primary-500 hover:bg-primary-600 text-white font-semibold'><MdOutlineDone  size={18}/></motion.button>
                </div>}
            </div>
            <div className='w-full max-w-3xl mt-8'>
                <label>Store Categories</label>
                <hr className='mb-2'/>
                {categories.map((a,idx)=>(<div key={idx}>
                        {editCategory!=idx && <div className='flex items-center justify-between w-full py-2 px-6 my-2 border border-gray-200 shadow-md rounded-xl'>
                            <p>{a.name}</p>
                            <div onClick={()=>setEditCategory(idx)} className='p-2 rounded-full hover:bg-primary-500 hover:text-white cursor-pointer'><BiEditAlt  size={24}/></div>
                        </div>}
                        {editCategory===idx && <div className="flex mb-4">
                            <input value={newCatName} onChange={(e)=>setNewCatName(e.target.value)} type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ps-10 px-6 py-4  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="New Name" required />
                            <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>navigator("/shop-editor")} className='ml-2 flex p-4 text-sm items-center rounded-xl bg-primary-500 hover:bg-primary-600 text-white font-semibold'><MdOutlineDone  size={18}/></motion.button>
                        </div>}
                    </div>)
                )}
                
                {addCategory && <div className="flex mb-4">
                    <input value={newCatName} onChange={(e)=>setNewCatName(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ps-10 px-6 py-4  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="New Category Name" required />
                    <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={addNewCategory} className='ml-2 flex p-4 text-sm items-center rounded-xl bg-primary-500 hover:bg-primary-600 text-white font-semibold'><MdOutlineDone  size={18}/></motion.button>
                </div>}
                <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>setAddCategory(true)} className=' flex p-4 text-sm items-center rounded-xl bg-primary-500 hover:bg-primary-600 text-white font-semibold'><CgAdd size={18} className='mr-2'/>New Category</motion.button>
            </div>
            
        </div>
    </div>
  )
}
