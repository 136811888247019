import React from "react";
import LayerRoundedIcon from "@mui/icons-material/LayersRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

function ImageEditToolbar({ layerUp, layerDown, bgRemove, removeImage }) {
  return (
    <div className="toolbar">
      <Tooltip title="Layer Up" arrow>
        <button onClick={layerUp}>
          <ArrowUpwardRoundedIcon />
        </button>
      </Tooltip>
      <Tooltip title="Layer Down" arrow>
        <button onClick={layerDown}>
          <ArrowDownwardRoundedIcon />
        </button>
      </Tooltip>
      <Tooltip title="Remove Background" arrow>
        <button onClick={bgRemove}>
          <LayerRoundedIcon />
        </button>
      </Tooltip>
      <Tooltip title="Remove Image" arrow>
        <button onClick={removeImage}>
          <DeleteIcon />
        </button>
      </Tooltip>
    </div>
  );
}

export default ImageEditToolbar;
