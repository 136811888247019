import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IDContextProvider } from "./Hooks/userContext";
import { AuthContextProvider } from "./Hooks/AuthContext";
import CartContextProvider from "./Hooks/CartContextProvider";
import ProtectedRoute from "./ProtectedRoute";

import Home from "./Pages/Landing/Home";
import Login from "./Pages/Authentication/LoginPrevious";
import CreateAccount from "./Pages/Authentication/CreateAccountPrevious";
import CreateCategory from "./Pages/ProductListing/CategoryOptions";
import CreateCommon from "./Pages/ProductListing/CreateListingHome";
import Pricing from "./Pages/Landing/Pricing";
import FAQs from "./Pages/Landing/FAQs";
import MyShop from "./Pages/ShopLanding/ShopLanding";
import ProductDetails from "./Pages/ShopLanding/ProductDetails";
import ShopEditor from "./Pages/ShopLanding/ShopEditor";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Tester from "./Pages/RAGTester[PCA]/Tester";
import PosPage from "./Pages/POS/PosPage";
import OrdersPage from "./Pages/POS/OrdersPage";
import PosHome from "./Pages/POS/PosHome";

import ShopManagementHome from "./Pages/ShopManagement/ShopManagementHome";
import EditCategory from "./Pages/ShopManagement/Subpages/EditCategory";
import EditSocialLinks from "./Pages/ShopManagement/Subpages/EditSocialLinks";
import EditTheming from "./Pages/ShopManagement/Subpages/EditTheming";
import EditDetails from "./Pages/ShopManagement/Subpages/EditDetails";
import EditCharges from "./Pages/ShopManagement/Subpages/EditCharges";
import Inventory from "./Pages/Inventory/Inventory";
import ProductListingFree from "./Pages/ProductListing/ProductListingFree";
import Categories from "./Pages/Category/Categories";
import EditDeliveryCharges from "./Pages/ShopManagement/Subpages/EditDeliveryCharges";



function App() {
  return (
    <IDContextProvider>
      <AuthContextProvider>
        <CartContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tester" element={<Tester />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/pos" element={<PosHome />} />
              <Route path="/pos-orders" element={<OrdersPage />} />
              <Route path="/faq" element={<FAQs />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/shop-management" element={<ShopManagementHome />} />
              <Route path="/shop-management/inventory" element={<Inventory />} />
              <Route path="/shop-management/category" element={<Categories />} />
              <Route path="/shop-management/charges" element={<EditCharges />} />
              <Route path="/shop-management/details" element={<EditDetails />} />
              <Route path="/shop-management/theming" element={<EditTheming />} />
              <Route path="/shop-management/social" element={<EditSocialLinks />} />
              <Route path="/shop-management/edit" element={<ShopManagementHome />} />
              <Route path="/shop-management/inventory" element={<ShopManagementHome />} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/myshop" element={<ProtectedRoute><MyShop /></ProtectedRoute>} />
              <Route path="/shop-editor" element={<ProtectedRoute><ShopEditor /></ProtectedRoute>} />
              <Route path="/product/:id" element={<ProductDetails />} />
              {/* <Route path="/category" element={<ProtectedRoute><CreateCategory /></ProtectedRoute>}/> */}
              <Route path="/listing" element={<ProtectedRoute><CreateCommon /></ProtectedRoute>}/>
              <Route path="/listing-free" element={<ProtectedRoute><ProductListingFree /></ProtectedRoute>}/>
              <Route path="/shop-management/category" element={<ProtectedRoute><Categories /></ProtectedRoute>}/>
              <Route path="/shop-management/delivery-charges" element={<ProtectedRoute><EditDeliveryCharges /></ProtectedRoute>}/>
            </Routes>
          </BrowserRouter>
        </CartContextProvider>
      </AuthContextProvider>
    </IDContextProvider>
  );
}

export default App;
