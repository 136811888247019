import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { BASE_URL } from "../../Data/apiData";
import SideNavbarWide from "../../Components/SideNavbarWide";
import SideNavbarShort from "../../Components/SideNavbarShort";
import { EditNote } from "@mui/icons-material";
import { BiEdit } from "react-icons/bi";
import { VscEdit } from "react-icons/vsc";
import CreateProductModal from './CreateProductModal'
import InventoryCard from './InventoryCard'
import EditProductModal from './EditProductModal'

async function getProductsForShop(shopId) {
  try {
    const response = await axios.get(`${BASE_URL}/products/shop/${shopId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching products:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

async function getShopCategoriesForShop(shopId) {
  try {
    const response = await axios.get(
      `${BASE_URL}/shop_categories/shop/${shopId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching shop categories:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

// Function to update an existing product
async function updateProduct(productId, productData) {
  try {
    const response = await axios.put(`${BASE_URL}/products/${productId}`, {
      images: productData.images,
      name: productData.name,
      shop_category_id: productData.shop_category_id,
      sub_category_id: productData.sub_category_id,
      previous_price: productData.previous_price,
      current_price: productData.current_price,
      product_code: productData.product_code,
      quantity: productData.quantity,
      warranty: productData.warranty,
      description: productData.description,
      active: productData.active,
    });
    console.log(productData.active)
    return response.data;
  } catch (error) {
    console.error(
      "Error updating product:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

function ProductCard({ product, categories, isExpanded, onToggle, onEdit }) {
  const categoryNames =
    product.shop_category_id?.map((id) => categories[id]) || [];

  return (
    <div className="product-card p-6 border border-gray-300 rounded-lg shadow-lg max-w-3xl mx-auto bg-white">
      <div className="flex items-center justify-between cursor-pointer" onClick={onToggle}>
        <img src={product.images[0]} alt={product.name} className="w-20 h-20 object-cover rounded-full mr-4"/>
        <div className="flex-1">
          <h3 className="text-2xl font-semibold text-gray-800 mb-1">{product.name}</h3>
          <p className="text-sm text-gray-600 mb-1">Code: {product.product_code || "N/A"}</p>
          <p className="text-lg font-medium text-green-600 mb-1">Price: ৳{product.current_price}</p>
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center space-x-2 text-indigo-500 hover:underline"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(product);
            }}>
            <FaEdit />
            <span>Edit</span>
          </button>
          <button className="text-indigo-500 hover:underline">{isExpanded ? "Hide Details" : "Show Details"}</button>
        </div>
      </div>
      {isExpanded && (
        <div className="mt-4 text-gray-700">
          <p className="mb-2">
            <span className="font-semibold">Quantity:</span> {product.quantity}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Description:</span>{" "}
            {product.description}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Availability:</span>{" "}
            {product.active ? "Available" : "Not Available"}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Previous Price:</span>{" "}
            {product.previous_price ? `৳${product.previous_price}` : "N/A"}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Warranty:</span>{" "}
            {product.warranty || "N/A"}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Shop Category:</span>{" "}
            {categoryNames.join(", ") || "N/A"}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Product Code:</span>{" "}
            {product.product_code || "N/A"}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Last Updated:</span>{" "}
            {new Date(product.last_updated).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
}







function Inventory() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(false);
  const [creatingProduct, setCreatingProduct] = useState(false);
  //const shopId = "52cb581c-3cdb-4576-9145-3b53693c5570"; // Replace with the actual shop ID
  const shopId=localStorage.getItem("bebsha-store") // Replace with the actual shop ID
  const [searchName, setSearchName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const reloadPage=()=>{
    window.location.reload()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        const [productsData, categoriesData] = await Promise.all([
          getProductsForShop(shopId),
          getShopCategoriesForShop(shopId),
        ]);

        setProducts(productsData);
        console.log(products)
        const categoriesMap = categoriesData.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});
        setCategories(categoriesMap);
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [shopId]);

  const toggleExpand = (productId) => {
    setExpandedProductId(expandedProductId === productId ? null : productId);
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
  };

  const handleSave = async (updatedProduct) => {
    try {
      await updateProduct(updatedProduct.id, updatedProduct);
      setProducts(
        products.map((p) => (p.id === updatedProduct.id ? updatedProduct : p))
      );
      setEditingProduct(null);
    } catch (error) {
      console.error("Failed to update product", error);
    }
  };

  if (loading) {
    return <div className="text-3xl text-center py-8">Loading...</div>;
  }

  return (
    <div className="p-6 md:pl-24 bg-gray-100 min-h-screen">
      {window.innerWidth<=600 && <SideNavbarWide/>}
      {window.innerWidth>600 && <SideNavbarShort/>}
      <button onClick={()=>setCreatingProduct(true)} className="z-10 fixed top-4 right-4 flex px-4 py-2 rounded-lg text-xs md:text-sm text-white bg-primary-500 hover:bg-primary-600">New Product</button>
      <h1 className="text-xl mt-12 md:mt-0 md:text-3xl font-semibold text-primary-500">My Product Inventories</h1>
      <h6 className="text-sm mb-4 text-left text-gray-800">View and manage your products and inventory </h6>      

      <h2 className="mt-12 font-semibold">Search</h2>
      <div className="flex flex-col md:flex-row justify-between mb-6 md:space-x-4">
        
        <input
          type="text"
          placeholder="Search by product name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="w-full p-3 mb-2 md:mb-0 border border-gray-300 rounded h-12"
        />
        
        <Select
          isClearable
          name="search_category"
          value={
            searchCategory
              ? { value: searchCategory, label: categories[searchCategory] }
              : null
          }
          onChange={(selectedOption) =>
            setSearchCategory(selectedOption ? selectedOption.value : "")
          }
          options={Object.entries(categories).map(([id, name]) => ({
            value: id,
            label: name,
          }))}
          className="w-full h-12"
          placeholder="Search by category"
          styles={{
            control: (base) => ({
              ...base,
              minHeight: "48px", // Ensure the height matches the input field
            }),
          }}
        />
        
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
        {products
          .filter((product) => {
            const matchesName = product.name
              .toLowerCase()
              .includes(searchName.toLowerCase());
            const matchesCategory =
              !searchCategory ||
              product.shop_category_id.includes(searchCategory);
            return matchesName && matchesCategory;
          })
          .map((product) => (
            <InventoryCard
              key={product.id}
              product={product}
              categories={categories}
              isExpanded={expandedProductId === product.id}
              onToggle={() => toggleExpand(product.id)}
              onEdit={handleEdit}
              reloadPage={reloadPage}
              
            />
          ))}
      </div>
      {editingProduct && (
        <EditProductModal
          product={editingProduct}
          categories={categories}
          onSave={handleSave}
          onCancel={() => setEditingProduct(false)}
        />
      )}
      {creatingProduct && (
        <CreateProductModal reloadPage={reloadPage} setCreatingProduct={setCreatingProduct}/>
      )}
    </div>
  );
}

export default Inventory;
