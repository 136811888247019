"use client"
import React,{useState} from 'react'
import {motion} from 'framer-motion'
import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../Hooks/AuthContext'
export default function Navbar({position="right"}) {
    const navigator=useNavigate()
    const { user,logout } = UserAuth();
    const [show,setShow]=useState(false)
    return (
    <div className='flex flex-col w-full items-center justify-center'>
        {window.innerWidth<600 && <button onClick={()=>setShow(!show)} className={`fixed z-50 top-4 right-4 bg-white hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-lg p-2 text-xl`}>{!show?<HamburgerMenuIcon width={22} height={22}/>:<Cross1Icon width={22} height={22}/>}</button>}
        {(show || window.innerWidth>700) && <nav className="fixed top-0 md:top-2 z-30 w-full md:w-3/5 xl:w-1/2 md:flex md:rounded-xl border border-gray-100 shadow-lg items-center justify-between px-4 py-2 bg-white dark:bg-slate-900">
            <div className="p-2 md:px-6 cursor-pointer" onClick={()=>{navigator("/")}}><img src="./logo.png" className=" block w-[30px] h-[30px] md:w-[50px] md:h-[50px]"/></div>
            <motion.p onClick={()=>{navigator("/")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">Home</motion.p>
            {user && <motion.p onClick={()=>{navigator("/dashboard")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">Dashboard</motion.p>}
            {user && <motion.p onClick={()=>{navigator("/myshop")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">My Shop</motion.p>}
            {user && <motion.p onClick={()=>{navigator("/listing")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">AI Listing</motion.p>}
            {user && <motion.p onClick={()=>{navigator("/pos")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">POS</motion.p>}
            {!user && <motion.p onClick={()=>{navigator("/pricing")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">Pricing</motion.p>}
            {!user && <motion.p onClick={()=>{navigator("/socials")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">Socials</motion.p>}
            {!user && <motion.p onClick={()=>{navigator("/about")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">About</motion.p>}
            {!user && <motion.p onClick={()=>{navigator("/faq")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">FAQs</motion.p>}
            {user && <motion.p onClick={()=>{logout()}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-5 cursor-pointer hover:bg-primary-500 font-bold hover:text-white py-1.5 border border-gray-200 rounded-xl">Logout</motion.p>}
            {!user && <motion.p onClick={()=>{navigator("/listing")}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-5 cursor-pointer hover:bg-primary-500 font-bold hover:text-white py-1.5 border border-gray-200 rounded-xl">Get Started</motion.p>}
        </nav>}
    </div>
    )
}
