import React,{useState,useEffect} from 'react'
import ShopDomain from '../../Components/Dashboard/ShopDomain';
import ShopBlocks from '../../Components/Dashboard/ShopBlocks';
import ShopBanner from '../../Components/Dashboard/ShopBanner';
import axios from 'axios';
import SideNavbarWide from '../../Components/SideNavbarWide';
import { BASE_URL } from '../../Data/apiData';
import { getShop } from '../../API/shops';
import SideNavbarShort from '../../Components/SideNavbarShort';
const Dashboard = () => {
  const [shopData,setShopData]=useState(null)
  const [loading,setLoading]=useState(true)
  const [failedFetch,setFailedFetch]=useState(false)
  const getData=async()=>{
    setLoading(true)
    try{
        const res = await getShop(localStorage.getItem("bebsha-store"))
        console.log(res)
        setShopData(res)
        setLoading(false)
    }
    catch(e){
        alert("Fetching failed :(")
        setLoading(false)
        setFailedFetch(true)
    }
    
    
  }
  useEffect(()=>{
    getData()
  },[])

  if(loading) {
    return(
    <div className='w-screen min-h-screen flex items-center justify-center bg-white dark:bg-gray-900 pt-24 p-4'>
      Loading Shop Details...
    </div>
    )}
  if(failedFetch) {
    return(
    <div className='w-screen min-h-screen flex items-center justify-center bg-white dark:bg-gray-900 pt-24 p-4'>
      Fetching shop details failed. Please try again
    </div>
    )}
  return (
    <div className='w-screen '>
        <SideNavbarShort/>
        <div className='w-full pl-20 md:pl-24 min-h-screen bg-white dark:bg-gray-900 pt-24 p-4'>
            <ShopBanner data={shopData}/>
            <ShopDomain/>
            <ShopBlocks/>
        </div>
    </div >
)}

export default Dashboard
