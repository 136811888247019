import { RiMenu3Fill, RiRestartLine } from "react-icons/ri";
import { GrChapterNext, GrChapterPrevious, GrClose, GrPowerShutdown } from "react-icons/gr";
import { IoExitOutline,IoSendSharp ,IoHelpSharp ,IoSettingsSharp   } from "react-icons/io5";
import { MdQrCodeScanner,MdOutlineSave, MdLightMode } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaMicrophone  } from "react-icons/fa";
import { RxRotateCounterClockwise } from "react-icons/rx";
import { BsFillMicMuteFill } from "react-icons/bs";
import React ,{useState,useEffect} from 'react'
import Card from "./Card";
import AssistantChatLong from "./AssistantChatLong";
import UserChatLong from "./UserChatLong";
import axios from 'axios'
import MDEditor from '@uiw/react-md-editor'


export default function Tester() {
  const [theme,setTheme]=useState('')
  const [operationMode,setOperationMode]=useState("chat") //chat or proactive
  useEffect(()=>{
    if(localStorage.getItem('color-theme')==='light'){
        setTheme('light')
    }else{
        setTheme('dark')
    }
},[])
const changeTheme=()=>{
    console.log("change")
    if(localStorage.getItem('color-theme')==='light'){
        console.log("dark")
        localStorage.setItem('color-theme','dark')
        setTheme("dark")
        document.documentElement.classList.add('dark');
    }else{
        localStorage.setItem('color-theme','light')
        setTheme('light')
        document.documentElement.classList.remove('dark');
        console.log("light")
    }
}


  const [modalOpen,setModalOpen]=useState(false)
  const [currentUserChat,setCurrentUserChat]=useState(null)
  const [generatingChat,setGeneratingChat]=useState(false)
  const [vectorData,setVectorData]=useState(null)
  const [chosenIdx,setChosenIdx]=useState(0)
  const [conversation,setConversation]=useState([
    {   
      role:"assistant",
      content:"Hello there ask me any questions about light"
    }
])
  const createConversation=async()=>{
      console.log("Starting Generation")
      setGeneratingChat(true)
      const userConvBody={
          role:"user",
          content:currentUserChat
      }
      setConversation((conversation)=>[...conversation,userConvBody])
      const conv=[...conversation,userConvBody]
      setConversation(conv)
      generateAnswer()
      
      setCurrentUserChat("")

  }
  const conversationRequest=async()=>{
    //bebshai-backend git repo
  const endpoint = "http://localhost:8000/vector-query"
  //const endpoint = "http://localhost:8000/vector-query-no-topic"
  const res = await axios.post(endpoint,{
      query:currentUserChat,
      limit:5
  })
  setVectorData(res.data)
  console.log("generation ended ",res.data)
  return res.data
    
}
  const generateAnswer=async()=>{
    const agentResponse=await conversationRequest()
    const agentConvBody={
        role:"assistant",
        content:agentResponse[chosenIdx]["explaination"]
    }
    console.log(agentConvBody)
    setConversation((conversation)=>[...conversation,agentConvBody])
    setGeneratingChat(false)
  }
  const forwardIdx=()=>{
    if(chosenIdx<=4){
        console.log("Forward")
        setChosenIdx(chosenIdx+1)
    }else{
        setChosenIdx(0)
    }
  }
  const reverseIdx=()=>{
    if(chosenIdx>=0){
        console.log("Reverse")
        setChosenIdx(chosenIdx-1)
    }else{
        setChosenIdx(4)
    }
  }



  return (
    <main className="relative overflow-hidden w-screen flex md:flex-row flex-col min-h-screen items-center justify-between bg-blue-50 dark:bg-gray-900 p-12 md:p-32">
      <div className="w-full md:w-1/2 flex flex-col items-start">
        
      {/**CHAT UI */}
      {operationMode=="chat" && <div class="relative w-full min-h-[700px] bg-white border border-gray-200 rounded-xl shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
          {/**CHATS */}
          <div className="absolute pb-20 top-0 no-scrollbar flex p-6 flex-col w-full max-h-[700px] overflow-y-scroll no-scrollbar">
            {/* <button type="button" onClick={saveConversation} class="z-20 absolute right-4 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><MdOutlineSave size={16} /></button>
            <button type="button" onClick={resetConversation} class="z-20 absolute right-16 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiReset size={16} /></button>
            <button type="button" onClick={loadConversation} class="z-20 absolute right-28 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FiDownloadCloud size={16} /></button> */}
              {conversation.map((a,idx)=>{
                  if(a.role=="assistant"){
                      return (<AssistantChatLong key={idx} text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                  }
                  else if(a.role=="user"){
                    return (<UserChatLong key={idx} text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                  }
                  
              })}
              {generatingChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
             
          </div>
          {/**INPUT */}
          <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
              <textarea onKeyDown={(e)=>{
                if(e.key=="Enter"){
                  e.preventDefault()
                  createConversation()
                }
              }} type="text" rows={1} class=" block w-full md:w-4/5 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
              <div className="flex">
                <button onClick={createConversation} class="h-full mx-1 text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><IoSendSharp /></button>
              </div>
          </div>
        </div>}
      </div>
      <div className="w-full md:w-1/2 flex flex-col items-start">
        
      {/**CHAT UI */}
      {vectorData!=null && <div class="relative w-full min-h-[700px] bg-white border border-gray-200 rounded-xl shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
          {/**CHATS */}
          <div className="absolute pb-20 no-scrollbar top-0  flex p-6 flex-col w-full max-h-[700px] overflow-y-scroll no-scrollbar">
            <img alt="vector" className="w-full p-2 border border-gray-200 shadow rounded" src={vectorData[chosenIdx]["image"]}/>
            
            <div href="#" class="w-full mt-2  p-4   border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
                {/*<pre style={{whiteSpace:"pre-wrap"}}>{text}</pre>*/}
                <MDEditor.Markdown className='dark:hidden' source={vectorData[chosenIdx]["explaination"]} style={{ background: "transparent",color:"black" }} />
                <MDEditor.Markdown className='hidden dark:block' source={vectorData[chosenIdx]["explaination"]} style={{ background: "transparent", }} />
                
            </div>
          </div>
          {/**INPUT */}
          <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
              <button onClick={reverseIdx} className="p-4 rounded bg-gray-600 hover:bg-gray-800 cursor-pointer text-white">
                <GrChapterPrevious/>
              </button>
              <p className="text-xs flex flex-col items-start">Match Score : <span className="text-md font-semibold px-4 py-2 rounded bg-white">{vectorData[chosenIdx]["score"]}</span></p>
              <button onClick={forwardIdx} className="p-4 rounded bg-gray-600 hover:bg-gray-800 cursor-pointer text-white">
                <GrChapterNext/>
              </button>
          </div>
        </div>}
      </div>
      
      
      <button  onClick={changeTheme} type="button" class="absolute right-4 md:right-4 top-4 md:top-12  shadow-lg text-blue-500 dark:bg-slate-700  dark:hover:bg-slate-700 dark:hover:border-slate-800 dark:border-slate-800 bg-white rounded-xl p-2 md:p-4 border border-gray-50 hover:border-gray-200 hover:text-blue-600 cursor-pointer">
        {theme==='light' && <svg id="theme-toggle-dark-icon" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>}
        {theme==='dark' && <svg id="theme-toggle-light-icon" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>}
      </button>
      <form class="max-w-sm mx-auto absolute right-20 md:right-20 top-4 md:top-12">
        
        <select id="countries" class="bg-gray-50 border border-gray-300 h-14 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option className="h-14 px-2" selected>GPT 3.5 Turbo</option>
            <option className="h-14 px-2" value="US">PCA RAG</option>
            <option className="h-14 px-2" value="CA">PCA Finetuned LLAMA3</option>
            <option className="h-14 px-2" value="FR">PCA Finetuned PHI3</option>
            <option className="h-14 px-2" value="DE">PAC RAG + LLAMA3</option>
        </select>
    </form>

      {/**Modal */}
      {modalOpen && <div id="default-modal" tabindex="-1" aria-hidden="true" class="bg-gray-500 dark:bg-opacity-20 bg-opacity-50 overflow-y-auto flex items-center justify-center overflow-x-hidden fixed top-0 right-0 left-0 z-2 justify-center items-center w-screen md:inset-0 h-full">
          <div class="relative p-4 w-full max-w-2xl max-h-full ">
              {/**Modal Content*/}

              {/**Modal Header*/}
              <div class="flex bg-white items-center justify-between p-4 md:p-5 border-b rounded-t-lg dark:bg-gray-700 dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                      Option Menu
                  </h3>
                  <button onClick={()=>setModalOpen(false)} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                      <GrClose/>
                  </button>
              </div>
              <div class="relative bg-white rounded-b-lg  dark:bg-gray-700 z-50" >
                  {/**Modal Body*/}
                  <div class="p-8 grid grid-cols-2 md:grid-cols-3 gap-4">
                    <Card icon={<RiRestartLine  className="text-2xl md:text-4xl"/>} title="Restart"/>
                    <Card icon={<GrPowerShutdown  className="text-2xl md:text-4xl"/>} title="Shutdown"/>
                    <Card icon={<IoExitOutline className="text-2xl md:text-4xl"/>} title="Exit"/>
                    <Card icon={<MdQrCodeScanner  className="text-2xl md:text-4xl"/>} title="QR Code"/>
                    <Card icon={<IoHelpSharp   className="text-2xl md:text-4xl"/>} title="Help"/>
                    <Card icon={<IoSettingsSharp  className="text-2xl md:text-4xl"/>} title="Settings"/>
                    <Card icon={<FaEye className="text-2xl md:text-4xl"/>} title="Proactive"/>
                    <Card icon={<RxRotateCounterClockwise className="text-2xl md:text-4xl"/>} title="Mode"/>
                    <Card icon={<BsFillMicMuteFill className="text-2xl md:text-4xl"/>} title="Mute"/>
                  </div>
              </div>
          </div>
      </div>}
    </main>
  );
}
