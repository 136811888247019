import React,{useEffect, useState} from 'react'
import { PiSpinnerLight } from "react-icons/pi";
import axios from "axios"
import SideNavbarWide from '../../../Components/SideNavbarWide';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Data/apiData';
export default function EditSocialLinks(){
    const navigator=useNavigate()

    const [updating,setUpdating]=useState(false)
    const [loading,setLoading]=useState(true)

    const [fb,setFb]=useState("")
    const [wa,setWa]=useState("")
    const [insta,setInsta]=useState("")
    const [yt,setYt]=useState("")
    const [ln,setLn]=useState("")
    const [gmaps,setGmaps]=useState("")
    const [discord,setDiscord]=useState("")
    const [telegram,setTelegram]=useState("")
    const [tiktok,setTiktok]=useState("")

    const getData=async()=>{
        const res= await axios.get(BASE_URL+"/shop/links/"+localStorage.getItem("oleyn-storeid"))
        console.log(res.data)
        setFb(res.data.facebook)
        setWa(res.data.whatsapp)
        setInsta(res.data.instagram)
        setYt(res.data.youtube)
        setLn(res.data.linkedin)
        setGmaps(res.data.googleMaps)
        setDiscord(res.data.discord)
        setTelegram(res.data.telegram)
        setTiktok(res.data.tiktok)
        setLoading(false)
    }

    const updateLinks=async()=>{
        setUpdating(true)
        const reqBody={
            facebook  :fb,
            whatsapp  :wa,
            instagram :insta,
            googleMaps :gmaps,
            youtube   :yt,
            linkedin  :ln,
            discord   :discord,
            telegram  :telegram,
            tiktok    :tiktok,
        
        }
        console.log(reqBody)
        const res = await axios.put(BASE_URL+"/shop/links/"+localStorage.getItem("oleyn-storeid"),reqBody)
        setUpdating(false)
        if(res.status===201){
            alert("Data Updated!")
        }
        console.log(res)
    }
    useEffect(()=>{
        getData()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            Loading Data..
        </div>)
    }
  return (
    <div className='w-screen min-h-screen max-w-screen flex flex-col dark:bg-slate-800 items-center justify-center'>    
        <SideNavbarWide active={1}/>
        <div className='relative w-screen md:pl-64 flex items-center justify-center min-h-screen dark:bg-slate-900'>

            <div className='w-full md:max-w-md max-h-[600px] overflow-y-scroll py-1 shadow-lg bg-white dark:bg-gray-900 flex items-center justify-center rounded-xl'>
               <div className='max-w-sm  md:max-w-md grid grid-cols-2 bg-white dark:bg-gray-900 p-2' >
                    {/** Text Inputs */}
                    <div className="max-w-sm md:max-w-md w-full mx-auto">
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Facebook Link</label>
                            <input value={fb} onChange={(e)=>setFb(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Whatsapp Number</label>
                            <input value={wa} onChange={(e)=>setWa(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Google Maps Link</label>
                            <input value={gmaps} onChange={(e)=>setGmaps(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Instagram Link</label>
                            <input value={insta} onChange={(e)=>setInsta(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Youtube Link</label>
                            <input value={yt} onChange={(e)=>setYt(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">LinkedIn Link</label>
                            <input value={ln} onChange={(e)=>setLn(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Discord Link</label>
                            <input value={discord} onChange={(e)=>setDiscord(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telegram Link</label>
                            <input value={telegram} onChange={(e)=>setTelegram(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tiktok Link</label>
                            <input value={tiktok} onChange={(e)=>setTiktok(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        
                        <div className='w-full flex items-center justify-between'>
                            <button onClick={updateLinks} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{updating?<p className='flex items-center'> <PiSpinnerLight className='text-lg'/> Updating Links..</p>:<p>Update Links</p>}</button>
                        </div>
                        
                        </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

