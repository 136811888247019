import React from 'react'
import Footer from '../../Components/Footer'
import {motion} from 'framer-motion'
import Navbar from '../../Components/Navbar'
import { useNavigate } from 'react-router-dom'
import { ArrowRightIcon } from '@radix-ui/react-icons'
export default function Home() {
    const navigator=useNavigate()
    return (
        <div className="row ">
        <Navbar />
        

        <section class="bg-white min-h-screen flex items-center justify-center dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
            <div class="py-8 px-2 md:px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                <div class="hidden md:inline-flex justify-between items-center py-1 px-1 pe-4 mb-7 text-xs md:text-sm text-primary-700 bg-primary-100 rounded-full dark:bg-primary-900 dark:text-primary-300 hover:bg-primary-200 dark:hover:bg-primary-800">
                    <span class="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 me-3">New</span> <span class="text-sm font-medium">Image editor component has been launched! See now</span> 
                    <svg class="w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                    </svg>
                </div>
                <h1 class="mb-2 md:mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-5xl dark:text-white">The simplest <span className="text-primary-500">product listing creator</span>  in the world</h1>
                <p class="mb-8  font-normal text-gray-500 text-sm lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">You don't have to spend hours to create a listing. Create it in a matter of seconds!</p>
                <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.8}} class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-md px-5 py-2.5 text-center dark:focus:ring-primary-900" onClick={()=>navigator("/dashboard")}>Get started<ArrowRightIcon size={32} className='ml-2'/></motion.button>
            </div>
            <div class="bg-gradient-to-b from-primary-50 to-transparent dark:from-primary-900 w-full h-full absolute top-0 left-0 z-0"></div>
        </section>
        <div class="bg-primary-50  dark:bg-gray-900">
            <div class="gap-8 h-screen items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <img class="w-full" src="https://i.postimg.cc/NFk7y8d6/1x1-Images-11.png" alt="dashboard" />
                <div class="mt-4 md:mt-0">
                    <h2 class="mb-4 text-center md:text-left text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Create listings <span class="text-primary-600 dark:text-primary-500">in seconds </span> </h2>
                    <p class="mb-6 text-center md:text-left  font-light text-gray-500 text-sm lg:text-xl dark:text-gray-400">Get data from several platforms to get an idea about what the other products in your cateory are doing and how they are performing. Create inventory based on that.</p>
                   
                    <div className='flex my-2 md:justify-start justify-center'>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            Product search accross the internet
                        </span>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            Dynamic pricing suggestor
                        </span>
                    </div>
                </div>
            </div>
        </div>
        
        <section class="bg-white min-h-screen flex items-center justify-center dark:bg-gray-900">
            <div class="py-8 px-6 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div class="max-w-screen-md mb-8 lg:mb-16">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for empowering businesses</h2>
                    <p class="text-gray-500 sm:text-xl dark:text-gray-400">Here at bebsha.ai we focus on brands where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
                </div>
                <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Image Detection</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">Click a picture and our model will find out what it is and follow the next steps based on that</p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path></svg>
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Image BG Remover</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">Automatically remove background from your images in the simplest possible UI</p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path><path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path></svg>                    
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Edit Images</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">Edit your images on our simple image editor. Create custom background and use that saved one later on as well</p>
                    </div>
                    
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Web-wide Product Search</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">We search the whole web for you to find if your product already exists somewhere in the web.</p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd"></path></svg>
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Automatic Detail Generator</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">Our AI models will generate the product description for you. </p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                        </div>
                        <h3 class="mb-2 text-xl font-bold dark:text-white">Price Suggestor</h3>
                        <p class="text-gray-500 text-sm lg:text-lg dark:text-gray-400">Our platform suggests the best price for optimal profit for your products.</p>
                    </div>
                </div>
            </div>
            </section>
            <div class="max-w-screen px-4 py-8 bg-primary-200  mx-auto text-center lg:py-16 lg:px-6 dark:bg-primary-700">
                <dl class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 dark:text-white">
                        <div class="flex flex-col items-center justify-center">
                            <dt class="mb-2 text-white text-3xl md:text-4xl font-extrabold">60k</dt>
                            <dd class="font-light text-gray-300 dark:text-gray-400">Targetted SMEs</dd>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <dt class="mb-2 text-white text-3xl md:text-4xl font-extrabold">6k</dt>
                            <dd class="font-light text-gray-300 dark:text-gray-400">Reached in year 1</dd>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <dt class="mb-2 text-white text-3xl md:text-4xl font-extrabold">7+</dt>
                            <dd class="font-light text-gray-300 dark:text-gray-400">Collaborations</dd>
                        </div>
                    </dl>
                
            </div>

       
       
        <div class="bg-white dark:bg-slate-700">
            <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <div class="mt-4 md:mt-0">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"><span class="text-primary-600 dark:text-primary-500">Augmented Intelligence</span> to drive digital sales</h2>
                    <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Use the power of AI to create product listings that are optimized for maximum sales accross several platforms in just one place</p>
            
                    <div className='flex my-2'>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            AI based listing generation
                        </span>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            Built-in image editor
                        </span>
                    </div>
                </div>
                <img  class="w-full dark:hidden" src="https://i.postimg.cc/R016yGXW/Untitled-design-10.png" alt="dashboard " />
                <img  class="w-full hidden dark:block" src="https://i.postimg.cc/R016yGXW/Untitled-design-10.png" alt="dashboard " />
            </div>
        </div>
       
        <div class="bg-primary-50  dark:bg-gray-900">
            <div class="gap-8 h-screen items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <img class="w-full " src="https://i.postimg.cc/9F1YCpRV/1x1-Images-12.png" alt="dashboard " />
                <div class="mt-4 md:mt-0">
                    <h2 class="mb-4 text-center md:text-left text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Empowered SMEs with <span class="text-primary-600 dark:text-primary-500">Data Driven </span> Approach </h2>
                    <p class="mb-6 text-center md:text-left  font-light text-gray-500 md:text-lg dark:text-gray-400">Get data from several platforms to get an idea about what the other products in your cateory are doing and how they are performing. Create inventory based on that.</p>
                   
                    <div className='flex my-2 md:justify-start justify-center'>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            Product search accross the internet
                        </span>
                        <span className={`bg-primary-100 text-primary-800 text-sm font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800`}>
                            Dynamic pricing suggestor
                        </span>
                    </div>
                </div>
            </div>
        </div>
        
        {/** Testimonials */}
        {/* <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Testimonials</h2>
                    <p class="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Listen about us from our users themselves</p>
                </div>
                
                <div class="grid mb-8 lg:mb-12 lg:grid-cols-2">
                    <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
                        <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                            <p class="my-4">"As an SME proprietor in Bangladesh, I was struggling to find my brand's identity and purpose until I discovered Bebsha.AI. Their inclusive and supportive environment empowered me to embrace my brand's uniqueness and develop my lifestyle. Thanks to BebshaAI, I now feel confident and equipped to navigate life with newfound clarity."</p>
                        </blockquote>
                        
                        <figcaption class="flex justify-center items-center space-x-3">
                            <img class="w-9 h-9 rounded-full" src="https://i.postimg.cc/Jhzkj5cj/image.png" alt="profile picture" />
                            <div class="space-y-0.5 font-medium dark:text-white text-left">
                                <div>Anonymous</div>
                                <div class="text-sm font-light text-gray-500 dark:text-gray-400"> Bangladesh</div>
                                
                            </div>
                        </figcaption>
                    </figure>
                    <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
                        <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                            <p class="my-4">"As an SME proprietor in Bangladesh, I was struggling to find my brand's identity and purpose until I discovered Bebsha.AI. Their inclusive and supportive environment empowered me to embrace my brand's uniqueness and develop my lifestyle. Thanks to BebshaAI, I now feel confident and equipped to navigate life with newfound clarity."</p>
                        </blockquote>
                        
                        <figcaption class="flex justify-center items-center space-x-3">
                            <img class="w-9 h-9 rounded-full" src="https://i.postimg.cc/Jhzkj5cj/image.png" alt="profile picture" />
                            <div class="space-y-0.5 font-medium dark:text-white text-left">
                                <div>Anonymous</div>
                                <div class="text-sm font-light text-gray-500 dark:text-gray-400"> Bangladesh</div>
                               
                            </div>
                        </figcaption>
                    </figure>
                   

                </div>
                <div class="text-center">
                    <a href="#" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Show more...</a>
                </div>
            </div>
        </section> */}
        <Footer/>
      

        
    </div>

    )
}
