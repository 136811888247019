import React from 'react'

export default function UserChatLong({text,avatar}) {
  return (
    <div className="flex my-2">
        <img className="w-12 h-12 p-1 mr-6 rounded-full bg-gray-200" src={avatar}/>
        <div href="#" class="w-4/5 bg-blue-500  p-4 bg-primary-400 text-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            <p>{text}</p>
        </div>
    </div>
  )
}
