import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from "react-icons/fa";
import { BASE_URL } from '../../Data/apiData';
import SideNavbarShort from '../../Components/SideNavbarShort';

const API_URL = BASE_URL+'/shop_categories';


const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', logo: '', banner: '', description: '' });
  const [editingCategory, setEditingCategory] = useState(null);
  const [addingCategory,setAddingCategory]=useState(false)
  //const shopId = "52cb581c-3cdb-4576-9145-3b53693c5570";
  const shopId=localStorage.getItem("bebsha-store")
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/shop/${shopId}`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching shop categories:", error.response ? error.response.data : error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingCategory({ ...editingCategory, [name]: value });
  };

  const handleAddCategory = async () => {
    try {
      const response = await axios.post(API_URL, {
        ...newCategory,
        shop_id: shopId
      });
      setCategories([...categories, response.data]);
      setNewCategory({ name: '', logo: '', banner: '', description: '' });
    } catch (error) {
      console.error("Error creating shop category:", error.response ? error.response.data : error.message);
    }
  };

  const handleEditCategory = async (categoryId) => {
    try {
      const response = await axios.put(`${API_URL}/${categoryId}`, editingCategory);
      setCategories(categories.map(category => category.id === categoryId ? response.data : category));
      setEditingCategory(null);
    } catch (error) {
      console.error("Error updating shop category:", error.response ? error.response.data : error.message);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${API_URL}/${categoryId}`);
      setCategories(categories.filter(category => category.id !== categoryId));
    } catch (error) {
      console.error("Error deleting shop category:", error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='w-screen min-h-screen p-8  pl-24'>
      <SideNavbarShort/>
    
      <h1 className="text-lg md:text-3xl mt-6 font-bold  text-primary-500">Manage Categories</h1>
      <h6 className="text-sm mb-6 text-left text-gray-800">View and manage your categories </h6> 
      <button onClick={()=>setAddingCategory(true)} className="fixed top-4 right-4 flex px-4 py-2 rounded-lg text-xs md:text-sm text-white bg-primary-500 hover:bg-primary-600">Add Category</button>
      {addingCategory && <div className="w-full bg-white border shadow-md rounded p-6 mb-8">
        <h2 className="text-lg font-semibold mb-4 text-gray-700">Add New Category</h2>
        <div className="flex flex-col  space-y-2">
          <label className="block">
            <span className="text-gray-700 text-sm font-semibold">Name <span className="text-red-500">*</span></span>
            <input
              type="text"
              name="name"
              value={newCategory.name}
              onChange={handleInputChange}
              className="mt-1 text-sm p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
              required
            />
          </label>
          {/* <label className="block">
            <span className="text-gray-700">Logo:</span>
            <input
              type="text"
              name="logo"
              value={newCategory.logo}
              onChange={handleInputChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Banner:</span>
            <input
              type="text"
              name="banner"
              value={newCategory.banner}
              onChange={handleInputChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </label> */}
          <label className="block">
            <span className="text-gray-700 text-sm font-semibold">Description</span>
            <input
              type="text"
              name="description"
              value={newCategory.description}
              onChange={handleInputChange}
              className="mt-1 p-2 text-sm border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </label>
        </div>
        <div className='flex space-x-2'>
          <button onClick={handleAddCategory} className="mt-4 text-sm font-semibold bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500">Add Category</button>
          <button onClick={()=>setAddingCategory(false)} className="mt-4 text-sm font-semibold bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500">Cancel</button>
        </div>
      </div>}
      
      {/* <h2 className="text-2xl font-semibold mb-4 text-gray-700">Existing Categories</h2> */}
      <div className="w-full flex flex-wrap space-y-2">
        {categories.map(category => (
          <div key={category.id} className="bg-white w-full border shadow-md rounded p-6">
            {editingCategory && editingCategory.id === category.id ? (
              <div >
                <span className="text-gray-700 text-sm font-semibold">Name</span>
                <input
                  type="text"
                  name="name"
                  value={editingCategory.name}
                  onChange={handleEditInputChange}
                  className="mb-2 text-sm p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                {/* <span className="text-gray-700 text-sm font-semibold">Description</span>
                <input
                  type="text"
                  name="logo"
                  value={editingCategory.logo}
                  onChange={handleEditInputChange}
                  className="mb-2 text-sm p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <span className="text-gray-700 text-sm font-semibold">Description</span>
                <input
                  type="text"
                  name="banner"
                  value={editingCategory.banner}
                  onChange={handleEditInputChange}
                  className="mb-2 text-sm p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
                /> */}
                <span className="text-gray-700 text-sm font-semibold">Description</span>
                <input
                  type="text"
                  name="description"
                  value={editingCategory.description}
                  onChange={handleEditInputChange}
                  className="mb-2 text-sm p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEditCategory(category.id)}
                    className="text-sm font-semibold bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setEditingCategory(null)}
                    className="text-sm font-semibold bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='flex flex-col md:flex-row items-start md:items-center justify-between'>
                <div >
                  <h3 className="text-xl font-semibold text-gray-800">{category.name}</h3>
                  {/* <p className="text-gray-600">
                    <strong>Logo:</strong> 
                    {category.logo ? (
                      <img src={category.logo} alt={`${category.name} logo`} className="mt-2 h-16 w-16 object-contain" />
                    ) : (
                      <span> No logo available</span>
                    )}
                  </p>
                  <p className="text-gray-600"><strong>Banner:</strong> {category.banner}</p> */}
                  <p className="text-sm text-gray-600">{category.description}</p>
                </div>
                <div className="flex space-x-2 mt-4">
                  <button onClick={() => setEditingCategory(category)} className="bg-primary-500 text-white p-3 text-sm rounded hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500"><FaEdit /></button>
                  <button onClick={() => handleDeleteCategory(category.id)} className="bg-red-500 text-white p-3 text-sm rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"><FaTrash /></button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>

  );
};

export default Categories;
