import React from 'react'
import SideNavbarWide from '../../Components/SideNavbarWide'
import { MdOutlineInventory2 } from "react-icons/md";
import { VscSymbolColor } from "react-icons/vsc";
import { IoReceiptOutline,IoShareSocialOutline,IoDocumentTextOutline  } from "react-icons/io5";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import { FiShoppingCart } from "react-icons/fi";
import { TbCategory,TbListDetails } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import SideNavbarShort from '../../Components/SideNavbarShort';



export default function ShopManagementHome(){
    const navigator=useNavigate()
  return (
    <div className="w-screen ">
        <SideNavbarShort/>
        
        <div className='relative bg-white w-screen pl-20 md:pl-24 min-h-screen flex flex-col md:items-center justify-center   dark:bg-gray-900 p-4 md:p-8'>
            {/* <Breadcrumb className="hidden md:block md:absolute top-10 left-72">
                <BreadcrumbList>
                <BreadcrumbItem>
                    <BreadcrumbPage>Shop Management</BreadcrumbPage>
                </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb> */}
            <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-4'>
                <button onClick={()=>navigator("/shop-management/inventory")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 py-4 md:py-10'>
                    <MdOutlineInventory2 size={32}  className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold dark:text-gray-200 my-2'>Manage Inventory</p>
                </button>
                <button onClick={()=>navigator("/shop-management/category")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <TbCategory size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-white my-2'>Manage Categories</p>
                </button>
                <button onClick={()=>navigator("/shop-management/inventory")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <FiShoppingCart size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-white my-2'>Manage Products</p>
                </button>
                {/* <button onClick={()=>navigator("/shop-management/pos-orders")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <IoReceiptOutline  size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-white my-2'>Receipts</p>
                </button> */}
                <button onClick={()=>navigator("/shop-management/delivery-charges")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <FaRegMoneyBill1  size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-white my-2'>Shop Charges</p>
                </button>
                <button onClick={()=>navigator("/shop-management/theming")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <IoDocumentTextOutline size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-gray-200 my-2'>T&C Pages</p>
                </button>
                {/* <button onClick={()=>navigator("/shop-management/theming")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <VscSymbolColor size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-gray-200 my-2'>Website Theme</p>
                </button>
                <button onClick={()=>navigator("/shop-management/socials")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <IoShareSocialOutline size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-gray-200 my-2'>Shop Social buttons</p>
                </button>
                <button onClick={()=>navigator("/shop-management/details")} className='w-full md:my-1 md:mx-2 min-h-sm flex flex-row md:flex-col items-center  rounded-lg border border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  py-4 md:py-10'>
                    <TbListDetails  size={32} className='dark:text-gray-200 mx-4'/>
                    <p className='text-lg font-semibold  dark:text-gray-200 my-2'>Edit Shop Details</p>
                </button> */}
            </div>
        </div>
    </div >
  )
}

