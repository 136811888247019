import React from 'react'
import { FaRegCopy } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import {motion} from "framer-motion"
const ShopDomain = () => {
  return (
    <motion.div initial={{y:-10}} animate={{y:0}} className='block md:hidden w-full my-4 min-h-sm flex flex-col items-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  p-4 '>
    <div className='flex flex-col w-full md:flex-row items-center justify-between '>
        <h2 className='dark:text-gray-100 font-semibold text-md font-body mb-2 md:mb-0 md:mr-4 w-full'>Shop Domain:</h2>
        <div class="flex relative w-full ">
            <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://www.oleyn.org/927" required />
            <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <FaRegCopy />
            </button>
            <button type="submit" class="hidden md:block p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <MdModeEdit  />
            </button>
        </div>
        <button type="submit" class="md:hidden w-full text-sm p-2 mt-4  font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <p className='text-sm md:text-md'>Change Domain</p>
        </button>
    </div>
   
</motion.div>
  )
}

export default ShopDomain
