import React, { useEffect, useState } from 'react'
import { TbSocial } from 'react-icons/tb'
import { MdCreate } from 'react-icons/md'
import { CgClose, CgProfile } from 'react-icons/cg'
import { BiLogOut } from 'react-icons/bi'
import { BsShop } from 'react-icons/bs'
import {BsPlugin} from 'react-icons/bs'
import {RiDashboardFill} from 'react-icons/ri'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import logo from './logo.png'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { PiTelevisionSimpleBold } from "react-icons/pi";

export default function SideNavbarShort({active}) {
  const navigator=useNavigate()
  const [theme, setTheme] = useState('')
  const [lan, setLan] = useState('english')

 

  const changeTheme = () => {
    console.log("change")
    if (localStorage.getItem('color-theme') === 'light') {
      console.log("dark")
      localStorage.setItem('color-theme', 'dark')
      setTheme("dark")
      document.documentElement.classList.add('dark');
    } else {
      localStorage.setItem('color-theme', 'light')
      setTheme('light')
      document.documentElement.classList.remove('dark');
      console.log("light")
    }
  }
  const changeLanguage = () => {
    console.log("change")
    if (localStorage.getItem('language') === 'english') {
      console.log("bangla")
      localStorage.setItem('language', 'bangla')
      setLan("bangla")
      document.documentElement.classList.add('bangla');
    } else {
      localStorage.setItem('language', 'english')
      setLan('english')
      document.documentElement.classList.remove('bangla');
      console.log("english")
    }
    window.location.reload()
  }
  useEffect(() => {
    if (localStorage.getItem('color-theme') === 'light') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
    if(localStorage.getItem('language')==='english'){
      setLan('english')
    }else{
        setLan('bangla')
    }
  }, [])
  

  return (
    <div>
      <aside id="default-sidebar" class="fixed top-0 left-0 z-40 w-16 h-screen" aria-label="Sidenav">
        <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700" >
          <div className='flex items-center mb-5 justify-start w-full' onClick={() => navigator("/")}>
            <img alt="logo" className='w-12 h-12 mr-2' src={logo} />
            
          </div>
          <div className="divider"></div>
          <ul class="space-y-2">
             <Tooltip title="Dashboard" placement='right'>
                <li><button onClick={()=>navigator("/dashboard")} class="flex items-center p-2 text-base font-normal  rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group text-gray-500" ><RiDashboardFill size={24}/></button></li>
            </Tooltip>
            <Tooltip title="Shop Management" placement='right'>
            <li><button onClick={()=>navigator("/shop-management")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" ><BsShop size={24}  /></button></li>
            </Tooltip>
            <Tooltip title="Orders" placement='right'>
            <li><button onClick={()=>navigator("/pos-orders")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" ><TbSocial size={24}  /></button></li>
            </Tooltip>
            <Tooltip title="Inventory" placement='right'>
            <li><button onClick={()=>navigator("/shop-management/inventory")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"><MdCreate size={24}  /></button></li>
            </Tooltip>
            {/* <Tooltip title="Profile" placement='right'>
            <li><button onClick={()=>navigator("/profile")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" ><CgProfile size={24}  /></button></li>
            </Tooltip> */}
            <Tooltip title="My Website" placement='right'>
            <li><button onClick={()=>navigator("/myshop")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" ><PiTelevisionSimpleBold size={24}  /></button></li>
            </Tooltip>
          </ul>
          <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
            {/* {installedPlugins.map(a => 
              (<li>
              <a href="#" class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                <BsPlugin size={24} />
                <span class="ml-3">{pluginItems[a].name}</span>
              </a>
            </li>)
            )} */}
            <Tooltip title="POS" placement='right'>
            <li>
              <button onClick={()=>navigator("/pos")} class="flex items-center p-2 text-base font-normal text-gray-500 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                <BsPlugin size={24} />

              </button>
            </li>
            </Tooltip>
          </ul>
          
        </div>

        {/** BOTTOM ICONS */}
        <div class=" absolute bottom-0 left-0 flex flex-col justify-center  w-16 lg:flex bg-white dark:bg-gray-800 z-20 border-r border-gray-200 dark:border-gray-700">
            <ul class="space-y-2">
                <Tooltip title="Logout" placement='right'><li><button  type="button" class="flex w-16 h-16 text-gray-500 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm" style={{ alignItems: "center", justifyContent: "center" }}>
                    <BiLogOut size={24}   /></button></li>
                </Tooltip>
                {/* <Tooltip title="Change Theme" placement='right'>
                <li>
                    <button onClick={changeTheme} type="button" class="text-gray-500 flex items-center justify-center w-16 h-16 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg ">
                        {theme === 'light' && <svg id="theme-toggle-dark-icon" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>}
                        {theme === 'dark' && <svg id="theme-toggle-light-icon" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>}
                    </button>
                </li>
                </Tooltip>
                <Tooltip title="Change Language" placement='right'>
                <li>
                    <button onClick={changeLanguage} type="button" class="flex w-16 h-16 text-gray-500 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm" style={{ alignItems: "center", justifyContent: "center" }}>
                        {lan === 'bangla' && <img alt="english" src="https://i.postimg.cc/63QDL1YV/england-4854979.png" width="20px" />}
                        {lan === 'english' && <img alt="bangla" src="https://i.postimg.cc/gkRkZnwz/bangladesh-9993444.png" width="20px" />}
                    </button>
                </li>
                </Tooltip> */}
            </ul>

        </div>
      </aside>
    </div>
  )
}
