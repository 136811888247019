import React from 'react'
import { BiCartAdd } from 'react-icons/bi'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
export default function ProductCard({image,title,subtitle,price,active}) {
  const navigator=useNavigate()
  return (
    <div class="w-full md:min-w-sm bg-white border border-gray-200 rounded-lg md:p-4 shadow dark:bg-gray-800 dark:border-gray-700">
        
        <motion.img  class="p-2 md:p-4 rounded-lg" src={image} alt="product image" />
        {active && <span class="mx-2 my-4 md:mx-4 bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Available</span>}
        {!active && <span class="mx-2 md:mx-4 bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Not Available</span>}
        <div class="px-2 md:px-4 pb-4">
            <motion.h5 whileHover={{translateX:15}} class="text-md md:text-lg font-semibold tracking-tight text-gray-900 dark:text-white cursor-pointer hover:text-primary-500 ">{title}</motion.h5>
            <p className='text-gray-600 text-xs md:text-sm mt-2 mb-4'>{subtitle}</p>
            <div class="flex items-center justify-between">
                <span class="text-xl md:text-3xl font-bold text-gray-900 dark:text-white">${price}</span>
                <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.8}} class="text-white bg-primary-500 hover:bg-primary-600  font-medium rounded-lg text-lg p-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 "><BiCartAdd/></motion.button>
            </div>
        </div>
    </div>
  )
}