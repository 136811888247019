import React,{useEffect, useState} from 'react'
import Navbar from '../../Components/Navbar'
import { QRCode } from 'react-qrcode-logo';
import {motion} from 'framer-motion'
import ProductCard from '../../Components/ShopLanding/ProductCard'
import { BiCategory, BiEditAlt } from 'react-icons/bi';
import { IoClose  } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../Data/apiData';
export default function ShopLanding() {
    const navigator=useNavigate()
    const [active,setActive]=useState("All")
    const [categories,setCategories]=useState([])
    const [loading,setLoading]=useState(false)
    const [products,setProducts]=useState([])
    const [showCatTab,setShowCatTab]=useState(false)

    async function getProductsForShop(shopId) {
        setLoading(true)
        try {
          const response = await axios.get(`${BASE_URL}/products/shop/${shopId}`);
          console.log(response.data)
          setProducts(response.data)
          //return response.data;
        } catch (error) {
          console.error(
            "Error fetching products:",
            error.response ? error.response.data : error.message
          );
          throw error;
        }
        setLoading(false)
      }
      
      async function getShopCategoriesForShop(shopId) {
        setLoading(true)
        try {
          const response = await axios.get(
            `${BASE_URL}/shop_categories/shop/${shopId}`
          );
          setCategories(response.data)
          //return response.data;
        } catch (error) {
          console.error(
            "Error fetching shop categories:",
            error.response ? error.response.data : error.message
          );
          throw error;
        }
        setLoading(false)
      }

      const getFilteredProducts = (id) => {
        if (id === "All") {
          return products;
        }
        return products.filter(product => product.shop_category_id.includes(id));
      };
    

    useEffect(()=>{
        const shopId=localStorage.getItem("bebsha-store")
        getShopCategoriesForShop(shopId)
        getProductsForShop(shopId)

    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <CircularProgress className='mr-2'/> Loading Data..
        </div>)
    }
    
  return (
    <div >
        <Navbar/>
        <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>setShowCatTab(!showCatTab)} className='absolute z-20 flex md:hidden top-4 left-4 p-4 rounded-xl bg-primary-500 hover:bg-primary-600 text-xs md:text-lg text-white font-semibold'>{!showCatTab ? <BiCategory className='flex md:hidden' size={16}/>:<IoClose  className='flex md:hidden' size={16}/>}</motion.button>
        <motion.button whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>navigator("/shop-editor")} className='absolute flex bottom-8 right-8 p-4 rounded-xl bg-primary-500 hover:bg-primary-600 text-xs md:text-lg text-white font-semibold'><BiEditAlt className='hidden md:flex' size={24}/><BiEditAlt className='flex md:hidden' size={16}/>Edit Shop</motion.button>
        <div className='w-screen h-screen flex items-center justify-center '>
            {/** Categories */}
            <div className='w-full h-screen flex md:pt-24'>
                <motion.div className={showCatTab?`w-full z-2 bg-white h-full absolute md:w-1/4 md:flex flex-col items-center justify-start p-8`:`hidden md:w-1/4 md:flex flex-col items-center justify-start p-8`}>
                    <h2 className='text-2xl mt-12 md:mt-0 md:text-5xl mb-4 md:mb-8 font-semibold text-primary-700'>Categories</h2>
                    <div className='flex flex-col w-full'>
                    <motion.h2 whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>setActive("All")} className={active=="All"?`bg-primary-500 text-white px-8 cursor-pointer py-4 border border-primary-700 shadow-lg rounded-xl m-1 font-semibold`:`px-8 cursor-pointer py-4 border border-gray-200 shadow-lg rounded-xl m-1 font-semibold`}>All</motion.h2>
                        {categories.map((a,idx)=>(
                            <motion.h2 whileHover={{scale:1.1}} whileTap={{scale:1}} onClick={()=>setActive(a.id)} className={active==a.id?`bg-primary-500 text-white px-8 cursor-pointer py-4 border border-primary-700 shadow-lg rounded-xl m-1 font-semibold`:`px-8 cursor-pointer py-4 border border-gray-200 shadow-lg rounded-xl m-1 font-semibold`}>{a.name}</motion.h2>
                        ))}
                    </div>
                    <div className='flex flex-col items-center mt-8 p-4 shadow-lg border border-gray-200'>
                        <QRCode size={100}  value="https://github.com/gcoro/react-qrcode-logo" />
                        <p className='font-semibold'>Scan Me</p>
                    </div>
                    
                </motion.div>
                <div className='w-full md:w-3/4 max-h-full p-4 md:p-8 bg-gray-200 overflow-y-scroll'>
                    <div className='w-full grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4'>
                        {getFilteredProducts(active).map((a, index) => (<ProductCard key={index}active={a.active} image={a.images[0]} title={a.name} subtitle={a.subtitle} price={a.current_price}/>))}    
                    </div>
                </div>
               
            </div>
        </div>
    </div>
  )
}
