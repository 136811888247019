import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../Components/Navbar'
import { FaCheck } from "react-icons/fa";
export default function Pricing() {
    const [lan, setLan] = useState('english')
    const [intermediateSelected, setintermediateSelected] = useState(false)
    const [proSelected, setProSelected] = useState(false)
    const [basicSelected, setBasicSelected] = useState(false)
    const [whSelected, setWhSelected] = useState(false)
    const [analyzerSelected, setAnalyzerSelected] = useState(false)
    const [intermediateCount, setintermediateCount] = useState(0)
    const [proCount, setProCount] = useState(0)
    const [basicCount, setBasicCount] = useState(0)
    const [anaylzerCount, setAnalyzerCount] = useState(0)
    const [whCount, setWCount] = useState(0)
    const navigator = useNavigate()
    useEffect(() => {
        if (localStorage.getItem('language') === 'bangla') {
            setLan('bangla')
        } else {
            setLan('english')
        }
    }, [])

    let basicCost = 4.99;
    let proCost = 19.99;
    let intermediateCost = 9.99;
    let analyzerPrice=85;
    let whPrice=65;


    return (
        <div className='w-screen min-h-screen p-8 md:pt-28'>
            <Navbar />
            <div class="bg-white w-full h-full  flex flex-col items-center dark:bg-gray-900">
                <div className="flex flex-col  w-full max-w-8xl items-center justify-center">
                    <h2 class="mb-4 text-center  text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Our <span class="text-primary-600 dark:text-primary-500">Pricing </span> </h2>
                    <p class="mb-6 text-center  font-light text-gray-500 md:text-lg dark:text-gray-400">Have a look at our pricing for both personal and enterprise use</p>
                </div>
                <div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3 max-w-8xl items-center justify-between">
                        <div href="#" class="flex items-center mx-4 max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <input type="checkbox" onChange={(e)=>{
                                if(e.target.checked==false){
                                    //console.log("Help")
                                    setBasicCount(0)
                                    setBasicSelected(false)
                                }
                                setBasicSelected(e.target.checked)
                            }} value={basicSelected} class="w-8 md:w-12 h-8 md:h-12 mr-8 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <div className="flex flex-col">
                                <h5 class="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Basic Package</h5>
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-md md:text-xl'>${basicCost}</span> per month</p>
                                <ul className='w-full text-xs md:text-lg mt-2'>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">Support upto 20 products</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">Support upto 2 sites</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">AI analyzer and recommender </span></li>
                                </ul>
                            </div>

                        </div>
                        <div href="#" class="flex items-center mx-4 max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <input type="checkbox"  onChange={(e)=>{
                                if(e.target.checked==false){
                                    console.log("Help")
                                    setintermediateCount(0)
                                }
                                setintermediateSelected(e.target.checked)
                            }} value={intermediateSelected} class="w-8 md:w-12 h-8 md:h-12 mr-8 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <div className="flex flex-col">
                                <h5 class="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Intermediate Package</h5>
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-md md:text-xl'>${intermediateCost}</span> per month</p>
                                <ul className='w-full text-xs md:text-lg mt-2'>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">Support upto 30 products</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">Support upto 4 sites</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">AI analyzer and recommender </span></li>
                                </ul>
                            </div>

                        </div>
                        <div href="#" class="flex items-center mx-4 max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <input type="checkbox" onChange={(e)=>{
                                if(e.target.checked==false){
                                    console.log("Help")
                                    setProCount(0)
                                }
                                setProSelected(e.target.checked)
                            }} value={proSelected} class="w-8 md:w-12 h-8 md:h-12 mr-8 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <div className="flex flex-col">
                                <h5 class="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Pro Package</h5>
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-md md:text-xl'>${proCost}</span> per month</p>
                                <ul className='w-full text-xs md:text-lg mt-2'>
                                <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">Unlimited products</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">All supported sites</span></li>
                                    <li className='flex items-center '><FaCheck size={24} color='green' className='text-bold mr-2' /><span class="text-md rounded-lg text-black dark:text-white">AI analyzer and recommender </span></li>
                                </ul>
                            </div>

                        </div>
                       
                        
                    </div>
                    <div className="my-4 w-full">
                        <h2 class="mb-4 text-left mx-4 text-lg md:text-2xl tracking-tight font-bold text-gray-900 dark:text-white"> Price Calculator </h2>
                        {basicSelected && <div class="my-2 flex flex-col md:flex-row md:items-center items-start justify-between mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <div className="flex flex-col">
                                <h5 class="text-md md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Basic Package</h5>
                                <p class="flex items-center font-normal text-sm md:text-lg text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-xl'>${basicCost}</span> per month</p>

                            </div>
                            <div className="flex flex-col">
                                <div className="flex md:mx-4">
                                    <button type="button" onClick={()=>{
                                        if(basicCount>0){
                                            setBasicCount(basicCount => basicCount -= 1)
                                        }
                                    }} class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-</button>
                                    <input type="text" onChange={(e) => setBasicCount(parseInt(e.target.value))} value={basicCount} class="min-w-6 md:min-w-12 h-8 md:h-12 text-center text-primary-600 bg-gray-100 font-bold border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <button onClick={() => {
                                        setBasicCount(basicCount => basicCount += 1)
                                    }} type="button" class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">+</button>
                                </div>
                                <span className='md:font-bold text-center mx-2 text-md md:text-xl'>months</span>
                            </div>
                            <div className="flex">
                                <p class="flex items-center font-normal text-md md:text-lg text-gray-700 dark:text-gray-400">Total Price : <span className='font-bold mx-2 text-xl'>${basicCost * basicCount}</span> </p>
                            </div>
                        </div>}
                        {intermediateSelected && <div class=" my-2 flex flex-col md:flex-row md:items-center items-start justify-between mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <div className="flex flex-col">
                                <h5 class="text-md md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Intermediate Package</h5>
                                <p class="flex items-center text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-xl'>${intermediateCost}</span> per month</p>

                            </div>
                            <div className="flex flex-col">
                                <div className="flex mx-4">
                                    <button type="button" onClick={()=>{
                                        if(intermediateCount>0){
                                            setintermediateCount(intermediateCount => intermediateCount -= 1)
                                        }
                                    }} class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-</button>
                                    <input type="text" onChange={(e) => setintermediateCount(parseInt(e.target.value))} value={intermediateCount} class="min-w-6 md:min-w-12 h-8 md:h-12 text-center text-primary-600 font-bold bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <button onClick={() => {
                                        setintermediateCount(intermediateCount => intermediateCount += 1)
                                    }} type="button" class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">+</button>
                                </div>
                                <span className=' text-center mx-2 text-md md:text-xl'>months</span>
                            </div>
                            <div className="flex">
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400">Total Price : <span className='font-bold mx-2 text-xl'>${intermediateCost * intermediateCount}</span> </p>
                            </div>


                        </div>}
                        {proSelected && <div class="flex my-2 flex-col md:flex-row md:items-center items-start justify-between mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <div className="flex flex-col">
                                <h5 class="text-md md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Pro Package</h5>
                                <p class="flex items-center text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-xl'>${proCost}</span> per month</p>

                            </div>
                            <div className="flex flex-col">
                                <div className="flex mx-4">
                                    <button type="button" onClick={()=>{
                                        if(proCount>0){
                                            setProCount(proCount => proCount -= 1)
                                        }
                                    }} class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-</button>
                                    <input type="text" onChange={(e) => setProCount(parseInt(e.target.value))} value={proCount} class="min-w-6 md:min-w-12 h-8 md:h-12 text-center text-primary-600 bg-gray-100 font-bold border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <button onClick={() => {
                                        setProCount(proCount => proCount += 1)
                                    }} type="button" class="px-2 md:px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">+</button>
                                </div>
                                <span className=' text-center mx-2 text-md md:text-xl'>months</span>
                            </div>
                            <div className="flex">
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400">Total Price : <span className='font-bold mx-2 text-xl'>${proCost * proCount}</span> </p>
                            </div>


                        </div>}
                        
                        {whSelected && <div href="#" class="w-full flex my-2 flex-col md:flex-row md:items-center items-start justify-between mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <div className="flex flex-col">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Inventory Manager</h5>
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-xl'>৳65</span> per month</p>

                            </div>
                            <div className="flex flex-col">
                                <div className="flex mx-4">
                                    <button type="button" onClick={()=>{
                                        if(intermediateCount>0){
                                            setWCount(whCount => whCount -= 1)
                                        }
                                    }} class="px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-</button>
                                    <input type="text" onChange={(e) => setWCount(parseInt(e.target.value))} value={whCount} class="min-w-12 h-12 text-center text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <button onClick={() => {
                                        setWCount(whCount => whCount += 1)
                                    }} type="button" class="px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">+</button>
                                </div>
                                <span className='font-bold text-center mx-2 text-xl'>months</span>
                            </div>
                            <div className="flex">
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400">Total Price : <span className='font-bold mx-2 text-xl'>৳{whPrice * whCount}</span> </p>
                            </div>


                        </div>}
                        {analyzerSelected && <div href="#" class="w-full flex my-2 flex-col md:flex-row md:items-center items-start justify-between mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                            <div className="flex flex-col">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Market Analyzer</h5>
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400"><span className='font-bold mr-2 text-xl'>৳85</span> per month</p>

                            </div>
                            <div className="flex flex-col">
                                <div className="flex mx-4">
                                    <button type="button" onClick={()=>{
                                        if(intermediateCount>0){
                                            setAnalyzerCount(anaylzerCount => anaylzerCount -= 1)
                                        }
                                    }} class="px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-</button>
                                    <input type="text" onChange={(e) => setAnalyzerCount(parseInt(e.target.value))} value={anaylzerCount} class="min-w-12 h-12 text-center text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <button onClick={() => {
                                        setAnalyzerCount(anaylzerCount => anaylzerCount += 1)
                                    }} type="button" class="px-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm   dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">+</button>
                                </div>
                                <span className='font-bold text-center mx-2 text-xl'>months</span>
                            </div>
                            <div className="flex">
                                <p class="flex items-center font-normal text-gray-700 dark:text-gray-400">Total Price : <span className='font-bold mx-2 text-xl'>৳{analyzerPrice * anaylzerCount}</span> </p>
                            </div>


                        </div>}
                    </div>
                    {(proSelected || basicSelected || intermediateSelected || analyzerSelected || whSelected) && 
                    <div className="mb-24 md:mb-0">
                        <hr/>
                        <div href="#" class="w-full flex items-center justify-end mx-4 p-6 bg-white  dark:bg-gray-800 dark:border-gray-700 ">
                            <p class="flex items-center text-xl font-bold text-gray-700 dark:text-gray-400">Grand Total : <span className='font-bold mx-2 text-3xl'>৳{(intermediateCost * intermediateCount)+(proCost*proCount)+(basicCost*basicCount)+(anaylzerCount*analyzerPrice)+(whCount*whPrice)}</span> </p>  
                        </div>
                    </div>}              
                </div>
            </div>
        </div>

    )
}
