import React,{useEffect, useState} from 'react'
import SideNavbarWide from '../../../Components/SideNavbarWide'
import { PiSpinnerLight } from "react-icons/pi";
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Data/apiData';



export default function EditTheming(){
    const navigator=useNavigate()

    const [updating,setUpdating]=useState(false)
    const [loading,setLoading]=useState(true)

    const [theme,setTheme]=useState("default")
    const [color,setColor]=useState("#2563eb")
    const [defaultMode,setDefaultMode]=useState("")
    const [defaultLan,setDefaultLan]=useState("")

    const getData=async()=>{
        const res= await axios.get(BASE_URL+"/shop/theme/"+localStorage.getItem("oleyn-storeid"))
        console.log(res.data)
        setTheme(res.data.theme)
        setColor(res.data.color)
        setDefaultMode(res.data.defaultMode)
        setDefaultLan(res.data.defaultLanguage)
        setLoading(false)
    }

    const updateTheming=async()=>{
        setUpdating(true)
        const reqBody={
            theme,
            color,
            defaultLanguage:defaultLan,
            defaultMode
        
        }
        console.log(reqBody)
        const res = await axios.put(BASE_URL+"/shop/theme/"+localStorage.getItem("oleyn-storeid"),reqBody)
        setUpdating(false)
        if(res.status===201){
            alert("Data Updated!")
        }
        console.log(res)
    }
    useEffect(()=>{
        getData()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            Loading Data..
        </div>)
    }
return (
    <div className='w-screen min-h-screen max-w-screen flex flex-col bg-gray-100 items-center justify-center'>    
        <SideNavbarWide/>
        <div className='relative w-screen md:pl-64 flex items-center justify-center min-h-screen'>
            {/* <Breadcrumb className="hidden md:block md:absolute top-10 left-72">
                <BreadcrumbList>
                <BreadcrumbItem>
                    <BreadcrumbLink >
                        <Link href="/shop-management">Shop Management</Link>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator/>
                <BreadcrumbItem>
                    <BreadcrumbPage>Edit Theme</BreadcrumbPage>
                </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb> */}
            <div className='w-full md:max-w-md my-4 p-6 shadow-lg bg-white flex items-center justify-center rounded-xl'>
            <div className='max-w-sm md:max-w-md bg-white dark:bg-gray-900 p-4' >
                    {/** Text Inputs */}
                    <div className="max-w-sm md:max-w-md w-full mx-auto">
                        <div className="mb-5">
                            <label className="block text-sm font-medium mb-2 dark:text-white">Website Theme</label>
                            <div className='flex'>
                                <div className="flex m-2 items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input checked={theme==="default"} onClick={()=>setTheme("default")} id="bordered-radio-1" type="radio" value="" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label className="w-full mx-2 py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Default Theme</label>
                                </div>
                                <div className="flex m-2 items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input checked={theme==="theme 1"} onClick={()=>setTheme("theme 1")} id="bordered-radio-2" type="radio" value="" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label className="w-full mx-2 py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Dracula Theme</label>
                                </div>
                                <div className="flex m-2 items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input checked={theme==="theme 2"} onClick={()=>setTheme("theme 2")} id="bordered-radio-2" type="radio" value="" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label className="w-full mx-2 py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Boba Theme</label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label className="block text-sm font-medium mb-2 dark:text-white">Theme Color</label>
                            <input value={color} onChange={(e)=>setColor(e.target.value)} type="color" className="p-2 h-32 w-full block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" />
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website Mode</label>
                            <select value={defaultMode} onChange={(e)=>setDefaultMode(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected >Choose theme</option>
                                <option value="light">Light Mode</option>
                                <option value="dark">Dark Mode</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website Language</label>
                            <select value={defaultLan} onChange={(e)=>setDefaultLan(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected >Choose language</option>
                                <option value="english">English</option>
                                <option value="bengali">Bengali</option>
                            </select>
                        </div>
                        
                        <div className='w-full flex items-center justify-between'>
                            <button onClick={updateTheming} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{updating?<p className='flex items-center'> <PiSpinnerLight className='text-lg'/> Updating Links..</p>:<p>Update Links</p>}</button>
                        </div>
                        
                        </div>
                </div>
                
            </div>
        </div>
    </div>
)
}

 