import axios from "axios";
import { BASE_URL } from '../Data/apiData';
const API_URL = BASE_URL+"/shops";

// Function to get a shop by ID
async function getShop(shopId) {
  try {
    const response = await axios.get(`${API_URL}/${shopId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching shop:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

// Function to create a new shop
async function createShop(shopData) {
  try {
    const response = await axios.post(API_URL, {
      name: shopData.name,
      active:shopData.active,
      domain: shopData.domain,
      phones: shopData.phones,
      address: shopData.address,
      image: shopData.image,
      lat: shopData.lat,
      lng: shopData.lng,
      country: shopData.country,
      type: shopData.type,
      email: shopData.email,
      bio: shopData.bio
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating shop:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

// Function to update an existing shop
async function updateShop(shopId, shopData) {
  try {
    const response = await axios.put(`${API_URL}/${shopId}`, {
      name: shopData.name,
      domain: shopData.domain,
      phones: shopData.phones,
      address: shopData.address,
      image: shopData.image,
      lat: shopData.lat,
      lng: shopData.lng,
      country: shopData.country,
      type: shopData.type,
      email: shopData.email,
      bio: shopData.bio,
      active: shopData.active
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error updating shop:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

// Function to delete a shop by ID
async function deleteShop(shopId) {
  try {
    const response = await axios.delete(`${API_URL}/${shopId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting shop:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

export {getShop, createShop, updateShop, deleteShop}

// Example usage:

// Get a shop by ID
// getShop('fb892154-e645-47ea-80cd-0d3dc510623d')
//   .then(data => console.log("Shop data:", data))
//   .catch(error => console.error("Error:", error));

// Create a new shop
// const newShop = {
//   name: "My Shop",
//   domain: "my-shop.com",
//   phones: ["+1234567890"],
// };
// createShop(newShop)
//   .then((data) => console.log("Created shop:", data))
//   .catch((error) => console.error("Error:", error));

// Update an existing shop
// const updatedShop = {
//   "name": "Updated Name",
//   "image": "No Image"
// };
// updateShop('fb892154-e645-47ea-80cd-0d3dc510623d', updatedShop)
//   .then(data => console.log("Updated shop:", data))
//   .catch(error => console.error("Error:", error));

// Delete a shop by ID
// deleteShop('19a2697c-4f72-4149-af40-2201bc9a4052')
//   .then(data => console.log("Deleted shop:", data))
//   .catch(error => console.error("Error:", error));

