// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBmzeQZ5rdbvgW0xNDGB1k88-v48EOQIXc",
    authDomain: "bebsha-ai.firebaseapp.com",
    projectId: "bebsha-ai",
    storageBucket: "bebsha-ai.appspot.com",
    messagingSenderId: "250051388013",
    appId: "1:250051388013:web:20d71f45d4cc50f911d564"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app