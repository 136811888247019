import React, { useState } from 'react'
import { Stepper, Step, StepLabel } from '@mui/material'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Navbar from '../../Components/Navbar';
import UploadImages from './Actions/UploadImages';
import ProductDetails from './Actions/ProductDetails';
import ChoosePlatforms from "./Actions/ChoosePlatforms"
import SideNavbarShort from '../../Components/SideNavbarShort';

export default function CreateCommon() {
    const steps = [
        {
            title:'Click Pictures',
            subtitle:"Click Pictures and generate images"
        },
        {
            title:'Product Details',
            subtitle:"Edit and generate product details"
        },
        // {
        //     title:'Price Suggestion',
        //     subtitle:"Pricing and platform suggestion"
        // },
        {
            title:'Confirmation',
            subtitle:"Confirm and upload your listing"
        },

    ];
    const [active, setActive] = useState(0)
    const [productName,setProductName]=useState(null)
    const [productImages,setProductImages]=useState([])
    const nextStep=()=>{
        setActive(active=>active+1)
    }
    const setProduct=(val)=>{
        setProductName(val)
    }
    const setProductImg=(val)=>{
        setProductImages(val)
    }
    return (
    <div className='p-4 md:p-8 pl-24 min-h-screen'>
       <SideNavbarShort />
       {/* {window.innerWidth>=600 && <SideNavbar />} */}
        <div className="flex items-center justify-center dark:bg-gray-900">
            <div className='flex flex-col h-full max-w-7xl md:mb-0 items-center  w-screen justify-center dark:bg-gray-900'>
            <ol class="flex flex-wrap items-center justify-center w-full p-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                {steps.map((a, idx)=>(<>
                {active==idx && <li onClick={()=>setActive(idx)} class="flex font-semibold cursor-pointer items-center text-primary-600 dark:text-primary-500">
                    <span class="flex items-center justify-center w-5 h-5 me-2 text-xs  border border-primary-600 rounded-full shrink-0 dark:border-primary-500">
                    {idx+1}
                    </span>
                    {a.title}
                    {!(idx==steps.length-1) && <MdKeyboardDoubleArrowRight className='ml-4' size={18}/>}
                </li>}
                {active!=idx &&  <li onClick={()=>setActive(idx)} class="flex cursor-pointer items-center">
                    <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                        {idx+1}
                    </span>
                    {a.title}
                    {!(idx==steps.length-1) && <MdKeyboardDoubleArrowRight className='ml-4' size={18}/>}
                </li>}
                </>
                ))}

            </ol>
                {active==0 && <UploadImages nextStep={nextStep} setProduct={setProduct} setProductImg={setProductImg}/>}
                {active==1 && <ProductDetails nextStep={nextStep} productName={productName} productImages={productImages}/>}
                {active==2 && <ChoosePlatforms productName={productName} productImages={productImages}/>}
                {active==4 && <ProductDetails nextStep={nextStep} productName={productName} productImages={productImages}/>}
                
            </div>
        </div>
    </div>
    )
}
