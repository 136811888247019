import React, { useState, useEffect } from "react";
import axios from "axios";
import Decimal from "decimal.js";
import data from "./data.json";
import ItemCard from "../../Components/POS/ItemCard";
import OrderCard from "../../Components/POS/OrderCard";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineEdit, MdDone } from "react-icons/md";
import { LuUserPlus2 } from "react-icons/lu";
import { IoMdPrint } from "react-icons/io";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import SideNavbarWide from "../../Components/SideNavbarWide";
import SideNavbarShort from "../../Components/SideNavbarShort";
import { BASE_URL } from '../../Data/apiData';

const PosHome = () => {
  const navigator = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editCustomerId, setEditCustomerId] = useState(false);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const shop_name = data.SHOP_NAME;
  const server_url = BASE_URL;
  const shop_id = localStorage.getItem("bebsha-store");

  useEffect(() => {
    async function fetchCategoriesAndProducts() {
      try {
        const [categoriesResponse, productsResponse] = await Promise.all([
          axios.get(`${server_url}/shop_categories/shop/${shop_id}`),
          axios.get(`${server_url}/products/shop/${shop_id}`)
        ]);

        const allCategory = { id: "0", name: "All" };
        setCategories([allCategory, ...categoriesResponse.data]);
        setProducts(productsResponse.data);
        setActiveTab("0"); // Set "All" as the default active tab
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchCategoriesAndProducts();
  }, [server_url, shop_id]);

  const addToOrder = (product) => {
    setOrderList((prevOrderList) => {
      const existingProduct = prevOrderList.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevOrderList.map((item) =>
          item.id === product.id
            ? {
                ...item,
                quantity: item.quantity + 1,
                total_price: new Decimal(item.unit_price).times(item.quantity + 1).toFixed(2),
              }
            : item
        );
      } else {
        return [
          ...prevOrderList,
          {
            id: product.id,
            quantity: 1,
            unit_price: new Decimal(product.current_price).toFixed(2),
            total_price: new Decimal(product.current_price).toFixed(2),
          },
        ];
      }
    });
  };

  const updateQuantity = (productId, delta) => {
    setOrderList((prevOrderList) =>
      prevOrderList.map((item) =>
        item.id === productId
          ? {
              ...item,
              quantity: Math.max(1, item.quantity + delta),
              total_price: new Decimal(item.unit_price).times(Math.max(1, item.quantity + delta)).toFixed(2),
            }
          : item
      )
    );
  };

  const removeProduct = (productId) => {
    setOrderList((prevOrderList) => prevOrderList.filter((item) => item.id !== productId));
  };

  const totalPrice = orderList
    .reduce((total, product) => new Decimal(total).plus(product.total_price).toFixed(2), new Decimal(0));

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const placeOrder = async () => {
    setPlacingOrder(true);
    const orderData = {
      shop_id,
      total_amount: new Decimal(totalPrice).toNumber(),
      discount: new Decimal(0).toNumber(),
      status: "pending",
      order_items: orderList.map(item => ({
        product_id: item.id,
        quantity: item.quantity,
        unit_price: new Decimal(item.unit_price).toNumber(),
        total_price: new Decimal(item.total_price).toNumber(),
      })),
    };

    try {
      const response = await axios.post(`${server_url}/orders/with-items`, orderData);
      console.log("Order placed successfully:", response.data);
      // Clear the order list after successful order placement
      setOrderList([]);
      setPlacingOrder(false);
    } catch (error) {
      console.error("Error placing order:", error.response ? error.response.data : error.message);
    }
  };

  const getFilteredProducts = (id) => {
    if (id === "0") {
      return filteredProducts;
    }
    return filteredProducts.filter(product => product.shop_category_id.includes(id));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex">
      {window.innerWidth <= 600 && <SideNavbarWide />}
      {window.innerWidth > 600 && <SideNavbarShort />}
      <button onClick={() => navigator('/pos-orders')} className="fixed bottom-4 right-4 p-3 rounded-full bg-indigo-500 hover:bg-indigo-600 text-white text-xl">
        <MdOutlineFeaturedPlayList />
      </button>
      {!showSidebar && <div className="w-full md:w-2/3 h-full no-scrollbar overflow-scroll md:pl-24 p-8 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">{shop_name}</h1>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search products..."
          className="w-full mb-4 p-2 border rounded"
        />
        <div className="flex justify-center bg-gray-200 p-4 mb-4">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setActiveTab(category.id)}
              className={`px-4 py-2 mx-2 ${activeTab === category.id ? "bg-blue-500 text-white" : "bg-white text-black"}`}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {getFilteredProducts(activeTab).map((product, index) => (
            <ItemCard key={index} product={product} addToOrderFunc={addToOrder} />
          ))}
        </div>
      </div>}
      {showSidebar &&
        <button onClick={() => { setShowSidebar(false) }} className="z-50 fixed top-20 left-0 p-2 bg-white shadow border">
          <FaAngleRight />
        </button>
      }
      {(window.innerWidth <= 600 && !showSidebar && orderList.length==0 ) &&
        <button onClick={() => setShowSidebar(true)} className="z-10 fixed top-4 right-0 p-2 bg-white shadow border">
          <FaAngleLeft />
        </button>
      }
      {(window.innerWidth <= 600 && !showSidebar && orderList.length!=0) &&
        <button onClick={() => setShowSidebar(true)} className="z-10 fixed top-4 right-0 px-4 p-2 bg-indigo-500 shadow border">

          <p className="font-semibold text-gray-200">{orderList.length}</p>
        </button>
      }
      {(window.innerWidth > 500 || showSidebar) && <div className="z-10 w-full md:w-1/3 p-8 shadow-lg">
        <div className="w-full">
          <h2 className="text-indigo-500 py-2 rounded-lg text-2xl font-bold mb-2">Order Details</h2>
          <div className="w-full flex">
            {!editCustomerId && <p className="px-4 mr-1 py-2 w-full bg-gray-100 rounded-lg text-sm" >Anonymous Customer</p>}
            {editCustomerId && <input type="number" className="w-full text-sm font-semibold border border-gray-100  rounded-lg px-4 py-2" placeholder="user id" />}
            {!editCustomerId && <button onClick={() => setEditCustomerId(true)} className="p-2 mx-1 rounded-lg bg-gray-100 hover:bg-gray-200"><MdOutlineEdit /></button>}
            {editCustomerId && <button onClick={() => setEditCustomerId(false)} className="p-2 mx-1 rounded-lg bg-gray-100 hover:bg-gray-200"><MdDone /></button>}
            <button className="p-2 ml-1 rounded-lg bg-gray-100 hover:bg-gray-200"><LuUserPlus2 /></button>
          </div>
        </div>

        {orderList.length !== 0 && <div>
          <div className="flex justify-between items-center my-4 text-lg font-semibold">
            <p className="flex-1 px-2 text-left">Items</p>
            <p className="flex-1 px-2 text-center">Qty</p>
            <p className="flex-1 px-2 text-right">Price</p>
          </div>
          {orderList.map((product) => (
            <OrderCard key={product.id} products={products} product={product} updateQuantity={updateQuantity} removeProduct={removeProduct} />
          ))}
          <hr />
        </div>}
        {orderList.length !== 0 && <div>
          <div className="w-full flex mt-2 items-center justify-between">
            <div className="text-sm">Subtotal:</div>
            <div className="text-sm">৳{totalPrice.toString()}</div>
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="text-sm">Discount:</div>
            <div className="text-sm">৳{0}</div>
          </div>
          <hr className="my-2" />
          <div className="w-full flex mt-2 items-center justify-between">
            <div className="text-sm">Total Amount:</div>
            <div className="text-md text-indigo-500 font-bold">৳{totalPrice.toString()}</div>
          </div>
        </div>}
        {orderList.length !== 0 && <div className="w-full flex justify-between gap-2">
          <button disabled={orderList.length === 0} onClick={() => { placeOrder() }} className="flex flex-1 cursor-pointer items-center justify-center mt-4 border border-indigo-500 text-white py-2 px-4 text-indigo-500 hover:bg-indigo-100 text-xl rounded w-full">
            <IoMdPrint className="text-indigo-500" />
          </button>
          {!placingOrder && <button onClick={placeOrder} className="flex-2 mt-4 cursor-pointer bg-indigo-500 text-white py-2 px-4 rounded w-full">
            Place Order
          </button>}
          {placingOrder && <button onClick={placeOrder} className="flex items-center justify-center flex-2 mt-4 cursor-pointer bg-indigo-500 text-white py-2 px-4 rounded w-full">
            <AiOutlineLoading3Quarters className="mr-2" />Placing Order...
          </button>}
        </div>}
      </div>}
    </div>
  );
};

export default PosHome;
