import React, { useState, useEffect } from "react";
import axios from "axios";
import { VscEdit } from "react-icons/vsc";
import { MinusIcon } from "@radix-ui/react-icons";
import { MdAdd, MdDone } from "react-icons/md";
import { BASE_URL } from "../../Data/apiData";
import { Switch } from "@mui/material";
async function updateProduct(productId, productData) {
  try {
    const response = await axios.put(`${BASE_URL}/products/${productId}`, {
      images: productData.images,
      name: productData.name,
      shop_category_id: productData.shop_category_id,
      sub_category_id: productData.sub_category_id,
      previous_price: productData.previous_price,
      current_price: productData.current_price,
      product_code: productData.product_code,
      quantity: productData.quantity,
      warranty: productData.warranty,
      description: productData.description,
      active: productData.active,
    });
    console.log(productData.active)
    return response.data;
  } catch (error) {
    console.error(
      "Error updating product:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

export default function InventoryCard({ product, categories, onToggle, onEdit,reloadPage }) {
    const [newQty,setNewQty]=useState(product.quantity)
    const [active,setActive]=useState(product.active)
    const [updating,setUpdating]=useState(false)

    const updateProductPost=async()=>{
      setUpdating(true)
      await updateProduct(product.id, {
        ...product,quantity:newQty,active:active
      });
      setUpdating(false)
      reloadPage()
    }
  return (
      <div className="relative w-full flex flex-col items-center justify-center product-card p-6 border border-gray-300 rounded-lg shadow-lg max-w-3xl bg-white">
        <div className="flex flex-col items-center justify-between cursor-pointer" onClick={onToggle}>
          <img src={product.images[0]} alt={product.name} className="w-[200px] h-[200px] object-contain rounded-md"/>
          <h3 className="text-2xl font-semibold text-gray-800">{product.name}</h3>
          <p className="text-sm text-gray-600 mb-1">Code: {product.product_code || "N/A"}</p>
        </div>
        <button className="absolute top-2 right-2 p-2 flex items-center bg-gray-100 shadow-md border hover:border-primary-500 hover:bg-gray-200 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            onEdit(product);
          }}>
            <VscEdit/> <span className="hidden text-xs ml-2">Edit Product details</span>
        </button>
        <div className="w-full flex justify-between">
          <div className="flex-1 flex justify-between my-4">
            <label class="z-5 inline-flex flex-col items-start cursor-pointer">
              <span class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
              <Switch checked={active} onChange={()=>setActive(!active)}/>
            </label>
            <div className="flex flex-col">
              <span class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quantity</span>
              <div className="flex">
                <button onClick={()=>{
                  if(newQty>0){
                    setNewQty(newQty-1)
                  }
                }} className="p-2 border "><MinusIcon/></button>
                <input type={newQty} value={newQty} onChange={(e)=>setNewQty(e.target.value)} className="w-12 text-center h-full border"/>
                <button onClick={()=>setNewQty(newQty+1)} className="p-2 border"><MdAdd/></button>
              </div>
            </div>
          </div>
        </div>
        {(active!=product.active || newQty != product.quantity) && <button onClick={updateProductPost} className=" flex items-center justify-center text-xs font-semibold w-full p-2 bg-primary-500 text-white hover:bg-primary-600 border"><MdDone className="mr-2"/>{updating?"Updating Product....":"Update Product"}</button>}
      </div>
    );
  }