import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { BASE_URL } from "../../../Data/apiData";

const API_URL = BASE_URL+"/shop_charges";

async function getAllShopCharges(shopId) {
  try {
    const response = await axios.get(`${API_URL}/shop/${shopId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching shop charges:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

async function createShopCharges(shopChargesData, shopId) {
  try {
    const response = await axios.post(API_URL, {
      shop_id: shopId,
      zone: shopChargesData.zone,
      charge: shopChargesData.charge,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating shop charges:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

async function updateShopCharges(shopChargesId, shopChargesData) {
  try {
    const response = await axios.put(`${API_URL}/${shopChargesId}`, {
      zone: shopChargesData.zone,
      charge: shopChargesData.charge,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error updating shop charges:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

async function deleteShopCharges(shopChargesId) {
  try {
    const response = await axios.delete(`${API_URL}/${shopChargesId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting shop charges:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

function EditDeliveryCharges() {
  const [shopCharges, setShopCharges] = useState([]);
  const [newCharge, setNewCharge] = useState({ zone: "", charge: 0 });
  const [editCharge, setEditCharge] = useState({ id: "", zone: "", charge: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [editingChargeId, setEditingChargeId] = useState(null);
  //const shopId = "52cb581c-3cdb-4576-9145-3b53693c5570";
  const shopId=localStorage.getItem("bebsha-store")
  const fetchShopCharges = async () => {
    try {
      const data = await getAllShopCharges(shopId);
      setShopCharges(data);
    } catch (error) {
      console.error("Error fetching shop charges:", error);
    }
  };

  useEffect(() => {
    fetchShopCharges();
  }, []);

  const handleCreate = async () => {
    try {
      await createShopCharges(newCharge, shopId);
      fetchShopCharges();
      setNewCharge({ zone: "", charge: 0 });
    } catch (error) {
      console.error("Error creating shop charge:", error);
    }
  };

  const handleEdit = async () => {
    try {
      await updateShopCharges(editCharge.id, {
        zone: editCharge.zone,
        charge: editCharge.charge,
      });
      fetchShopCharges();
      setEditCharge({ id: "", zone: "", charge: 0 });
      setIsEditing(false);
      setEditingChargeId(null);
    } catch (error) {
      console.error("Error updating shop charge:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteShopCharges(id);
      fetchShopCharges();
    } catch (error) {
      console.error("Error deleting shop charge:", error);
    }
  };

  return (
    <div className="container mx-auto p-4 md:p-6 lg:p-8 max-w-screen-md">
      <div className="mb-8 md:mb-10 bg-white p-4 md:p-6 rounded-lg shadow-md">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-black-500">
          Add New Charge
        </h2>
        <div className="flex flex-col space-y-3">
          <input
            className="p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="text"
            placeholder="Zone"
            value={newCharge.zone}
            onChange={(e) =>
              setNewCharge({ ...newCharge, zone: e.target.value })
            }
          />
          <input
            className="p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="number"
            placeholder="Charge"
            min={0}
            step={1}
            value={newCharge.charge}
            onChange={(e) =>
              setNewCharge({ ...newCharge, charge: parseInt(e.target.value) })
            }
          />
          <button
            className="p-2 md:p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
            onClick={handleCreate}
          >
            Add Charge
          </button>
        </div>
      </div>

      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-black">
          Shop Charges
        </h2>
        <ul className="space-y-4">
          {shopCharges.map((charge) => (
            <li
              key={charge.id}
              className="p-4 border border-gray-300 rounded-lg flex flex-col md:flex-row justify-between items-start md:items-center bg-white shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              {editingChargeId === charge.id ? (
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full space-y-2 md:space-y-0 md:space-x-4">
                  <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto">
                    <input
                      className="w-full  p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                      type="text"
                      placeholder="Zone"
                      value={editCharge.zone}
                      onChange={(e) => setEditCharge({ ...editCharge, zone: e.target.value })}
                    />
                    <input
                      className="w-full  p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                      type="number"
                      placeholder="Charge"
                      min={0}
                      step={1}
                      value={editCharge.charge}
                      onChange={(e) => setEditCharge({ ...editCharge, charge: parseInt(e.target.value) })}
                    />
                  </div>
                  <div className="flex space-x-3 mt-2 md:mt-0">
                    <button
                      className="p-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
                      onClick={handleEdit}
                    >
                      Save
                    </button>
                    <button
                      className="p-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
                      onClick={() => setEditingChargeId(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full space-y-2 md:space-y-0 md:space-x-4">
                  <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto">
                    <div className="flex items-center">
                      <span className="block text-gray-500">Zone:</span>
                      <span className="block ml-2 text-lg text-gray-800">
                        {charge.zone}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="block text-gray-500">Charge:</span>
                      <span className="block ml-2 text-lg text-gray-800">
                      ৳{charge.charge}
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-3 mt-2 md:mt-0">
                    <button
                      className="p-2 bg-purple-500 text-white rounded-full hover:bg-purple-600 transition duration-300"
                      onClick={() => {
                        setEditCharge(charge);
                        setEditingChargeId(charge.id);
                      }}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300"
                      onClick={() => handleDelete(charge.id)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EditDeliveryCharges;
