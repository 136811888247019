import React from 'react'
import { motion } from "framer-motion"
const ShopBlocks = () => {
  return (
    <div className='flex w-full flex-col md:flex-row'>
      {/**SHOP Visits */}
      <motion.div initial={{y:15}} animate={{y:10}} className='w-full my-4 md:mx-2 min-h-sm flex flex-col justify-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  p-4 '>
          <div className='flex flex-col w-full items-center justify-between '>
              <h2 className='dark:text-gray-100 font-semibold text-md font-body mb-2 md:mb-0 md:mr-4 w-full'>Site Visits Today:</h2>
              <h2 className='text-primary-500 font-semibold text-4xl font-body md:mb-0 md:mr-4 w-full'>3245</h2>
              <hr class="w-full h-1 mx-auto my-4 md:my-2 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
              <h2 className='dark:text-gray-100 font-semibold text-md font-body my-2 md:mb-0 md:mr-4 w-full'>Total Site Visits:</h2>
              <h2 className='dark:text-white font-semibold text-4xl font-body md:mb-0 md:mr-4 w-full'>546334</h2>
              <h2 className='dark:text-gray-100  text-md font-body  w-full'>in last 45 days</h2>
          </div>
      </motion.div>
      {/**SHOP Sales */}
      <motion.div initial={{y:35}} animate={{y:0}} className='w-full my-4 md:mx-2 min-h-sm flex flex-col justify-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  p-4 '>
          <div className='flex flex-col w-full items-start justify-between '>
              <h2 className=' dark:text-gray-100 font-semibold text-md font-body mb-2 md:mb-0 md:mr-4 w-full'>Sales Today:</h2>
              <h2 className='bg-primary-500 p-4 px-6 rounded-lg text-white font-semibold text-4xl font-body md:mb-0 md:mr-4 '>BDT 3245</h2>
              <hr class="w-full h-1 mx-auto my-4 md:my-2 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
              <h2 className='dark:text-gray-100 font-semibold text-md font-body my-2 md:mb-0 md:mr-4 w-full'>Total Sales:</h2>
              <h2 className='dark:text-white  font-semibold text-4xl font-body md:mb-0 md:mr-4 w-full'>BDT 546334</h2>
              <h2 className='dark:text-gray-100  text-md font-body  w-full'>in last 45 days</h2>
          </div>
      </motion.div>
      {/**SHOP Items */}
      <motion.div initial={{y:15}} animate={{y:10}} className='w-full my-4 md:mx-2 min-h-sm flex flex-col justify-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  p-4 '>
          <div className='flex flex-col w-full items-center justify-between '>
              <h2 className='dark:text-gray-100 font-semibold text-md font-body mb-2 md:mb-0 md:mr-4 w-full'>Most Sold Item:</h2>
              <h2 className='text-primary-500 font-semibold text-4xl font-body md:mb-0 md:mr-4 w-full'>3245</h2>
              <hr class="w-full h-1 mx-auto my-4 md:my-2 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
              <h2 className='dark:text-gray-100 font-semibold text-md font-body my-2 md:mb-0 md:mr-4 w-full'>Low Inventory:</h2>
              <h2 className='dark:text-white  font-semibold text-4xl font-body md:mb-0 md:mr-4 w-full'>546334</h2>
              <h2 className='dark:text-gray-100  text-md font-body  w-full'>in last 45 days</h2>
          </div>
      </motion.div>
  </div>
  )
}

export default ShopBlocks
