import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { FaCheck, FaEdit } from 'react-icons/fa'
import Canvas from '../../../Components/Canvas/Canvas'
import axios from 'axios'
import imgbbUpload from "imgbb-image-uploader";
import { BASE_URL } from '../../../Data/apiData'
export default function UploadImages({nextStep,setProduct,setProductImg}) {
    const [img1, setImg1] = useState(null)
    const [img2, setImg2] = useState(null)
    const [img3, setImg3] = useState(null)
    const [img1URL, setImg1URL] = useState(null)
    const [img2URL, setImg2URL] = useState(null)
    const [img3URL, setImg3URL] = useState(null)

    const [img1Open,setImg1Open]=useState(false)
    const [img2Open,setImg2Open]=useState(false)
    const [img3Open,setImg3Open]=useState(false)

    const [editingImage,setEditingImage]=useState(false)
    const [editableImage,setEditableImage]=useState(null)
    const [currentChangeIndex,setCurrentChangeIndex]=useState(-1)

    const [response1,setResponse1]=useState(null)
    const [response2,setResponse2]=useState(null)
    const [response3,setResponse3]=useState(null)
    const [generating, setGenerating] = useState(false)
    
    const [generatingDetails, setGeneratingDetails] = useState(false)
    const [doneGeneration, setDoneGeneration] = useState(false)

    const hideCanvas=()=>{
        setEditingImage(false)
    }
    const changeURLFromCanvas=(new_url)=>{
        if(currentChangeIndex===1){
            setImg1URL(new_url)
        }
        if(currentChangeIndex===2){
            setImg2URL(new_url)
        }
        if(currentChangeIndex===3){
            setImg3URL(new_url)
        }
    }
    const imageUpload=async(file)=>{
        setGenerating(true)
        if(img1){
            const  res1= await imgbbUpload({
                key: "43853d449b56a2e477fd95dc2872c319", // your imgbb api key
                image: img1, // image selected from the file input
                expiration: 600, // 10 mins
                //name: shopName, // name of the image
              })
            setImg1URL(res1.data.url)
        }
        if(img2){
            const  res2= await imgbbUpload({
                key: "43853d449b56a2e477fd95dc2872c319", // your imgbb api key
                image: img2, // image selected from the file input
                expiration: 600, // 10 mins
                //name: shopName, // name of the image
              })
            setImg2URL(res2.data.url)
        }
        if(img3){
            const  res3= await imgbbUpload({
                key: "43853d449b56a2e477fd95dc2872c319", // your imgbb api key
                image: img3, // image selected from the file input
                expiration: 600, // 10 mins
                //name: shopName, // name of the image
              })
            setImg3URL(res3.data.url)
        }
        
        setDoneGeneration(true)
        setGenerating(false)
    }
    const generateImages = async() => {
        setGenerating(true);
        setDoneGeneration(false)
        // const timer = setTimeout(() => {
        //     setGenerating(false)
        //     setDoneGeneration(true)
        // }, 1000);
        // console.log("Timer done")
        // return () => clearTimeout(timer)
        
        const res1=await axios.post(BASE_URL+"/image_query/bg_remove",{
            "image_url":img1URL
        })
        setResponse1(res1.data.image_url)
        const res2=await axios.post(BASE_URL+"/image_query/bg_remove",{
            "image_url":img2URL
        })
        
        setResponse2(res2.data.image_url)

        const res3=await axios.post(BASE_URL+"/image_query/bg_remove",{
            "image_url":img3URL
        })
        setResponse3(res3.data.image_url)

        console.log(res1.data,res2.data,res3.data)

        setProductImg([res1.data,res2.data,res3.data])
        setGenerating(false)
        setDoneGeneration(true)
    }
    const generateProductType=async()=>{
        if(img1URL==null){
            alert("Upload an image first")
            return
        }
        setGeneratingDetails(true)
        setProductImg([img1URL,img2URL,img3URL])
        const res=await axios.post(BASE_URL+"/image_query/describe_image/",{
            "image_url":img1URL
        })
        console.log(res.data)
        await setProduct(res.data)
        setGeneratingDetails(false)
        nextStep()
    }
    if(editingImage){
        return (
        <div className='w-full py-8 bg-white border border-gray-200 rounded-lg shadow '>
            <Canvas preset_image={editableImage} hideCanvas={hideCanvas} currentChangeIndex={currentChangeIndex} changeURLFromCanvas={changeURLFromCanvas}/>
        </div>
    )}
    return (
        <>
            {<div className='w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-4 md:px-24 bg-white border border-gray-200 rounded-lg shadow p-4 md:p-6 dark:bg-gray-800 dark:border-gray-700 '>

                <input type="file" id="imgUpload1" className="hidden" onChange={e => {
                        setImg1((e.target.files[0]))
                        setImg1URL(URL.createObjectURL(e.target.files[0]))
                    }} />
                {img1 == null && <label for="imgUpload1" class="flex md:flex-col items-center justify-center max-w-sm mr-1 md:mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img className='hidden md:flex' alt="Not uploaded" src="https://i.postimg.cc/k4B4hm38/1.png" style={{ width: "200px" }} />
                    <img className='flex md:hidden' alt="Not uploaded" src="https://i.postimg.cc/k4B4hm38/1.png" style={{ width: "100px" }} />
                    Click Front View
                </label>}
                {img1 != null && <label for="imgUpload1" class="relative flex md:flex-col items-center justify-center max-w-sm  mr-1 md:mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div onClick={()=>{
                            setCurrentChangeIndex(1)
                            setEditableImage(img1URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                    <img className='hidden md:flex' alt="Not uploaded" src={img1URL} style={{ width: "300px" }} />
                    <img className='flex md:hidden' alt="Not uploaded" src={img1URL} style={{ width: "200px" }} />
                </label>}
                <input type="file" id="imgUpload2" className="hidden" onChange={e => {
                        setImg2((e.target.files[0]))
                        setImg2URL(URL.createObjectURL(e.target.files[0]))
                    }} />
                {img2 == null && <label for="imgUpload2" class="flex w-full md:flex-col items-center justify-center max-w-sm mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img className='hidden md:flex' alt="Not uploaded" src="https://i.postimg.cc/Jnvhf2MF/2.png" style={{ width: "200px" }} />
                    <img className='flex md:hidden' alt="Not uploaded" src="https://i.postimg.cc/Jnvhf2MF/2.png" style={{ width: "100px" }} />
                    Click Top View
                </label>}
                {img2 != null && <label for="imgUpload2" class="relative flex md:flex-col items-center justify-center max-w-sm mr-1 md:mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div onClick={()=>{
                            setCurrentChangeIndex(2)
                            setEditableImage(img2URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                    <img className='hidden md:flex' alt="Not uploaded" src={img2URL} style={{ width: "300px" }} />
                    <img className='flex md:hidden' alt="Not uploaded" src={img2URL} style={{ width: "200px" }} />
                </label>}

                <input type="file" id="imgUpload3" className="hidden" onChange={e => {
                        setImg3((e.target.files[0]))
                        setImg3URL(URL.createObjectURL(e.target.files[0]))
                    }} />
                {img3 == null && <label for="imgUpload3" class="flex md:flex-col items-center justify-center max-w-sm mr-1 md:mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img className='hidden md:flex' alt="Not uploaded" src="https://i.postimg.cc/D0GznPzw/3.png" style={{ width: "200px" }}/>
                    <img className='flex md:hidden' alt="Not uploaded" src="https://i.postimg.cc/D0GznPzw/3.png" style={{ width: "100px" }}/>
                    Click Right View
                </label>}
                {img3 != null && <label for="imgUpload3" class="relative flex md:flex-col items-center justify-center max-w-sm  mr-1 md:mr-4 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white text-center dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div onClick={()=>{
                            setCurrentChangeIndex(3)
                            setEditableImage(img3URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                    <img className='hidden md:flex' alt="Not uploaded" src={img3URL} style={{ width: "300px" }} />
                    <img className='flex md:hidden' alt="Not uploaded" src={img3URL} style={{ width: "200px" }} />

                </label>}
            </div>}
            {/* {!generating && !doneGeneration &&<div className='w-full max-w-7xl mt-4 md:px-24 flex flex-col md:flex-row items-center justify-center'>
                <button
                    className="w-full bg-primary-500 text-white rounded-lg font-bold uppercase px-6 py-2 text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={imageUpload}
                >
                {!generating && "Generate Images"}
                {generating && <div><CircularProgress/> Generating...</div>}
                </button>
            </div>}
            {generating && <div className='flex my-2 items-center'>
                <CircularProgress />
                <span className='dark:text-white ml-2'>Generating Images...</span>
            </div>}
            {doneGeneration && <div class="flex w-full  flex-col  items-center mx-4 mt-2 p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 ">
                <div className="flex items-center mb-2">
                    <h5 class="md:mb-2 text-md md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">Your Images have been generated</h5>
                    <FaCheck size={32} color='green' className='hidden md:flex text-bold ml-2' />
                    <FaCheck size={18} color='green' className='flex md:hidden text-bold ml-2' />
                </div>
                <div className="flex flex-col md:flex-row w-full md:mt-4 items-center justify-between">
         

                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(1)
                            setEditableImage(img1URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='hidden md:flex' alt="Not uploaded" src={img1URL} style={{ width: "300px" }} />
                    </div>
                }
                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(1)
                            setEditableImage(img1URL)
                            setEditingImage(true)
                        }} className='flex md:hidden absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='flex md:hidden' alt="Not uploaded" src={img1URL} style={{ width: "200px" }} />
                    </div>
                }
                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(2)
                            setEditableImage(img2URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='hidden md:flex' alt="Not uploaded" src={img2URL} style={{ width: "300px" }} />
                    </div>
                }
                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(2)
                            setEditableImage(img2URL)
                            setEditingImage(true)
                        }} className='flex md:hidden absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='flex md:hidden' alt="Not uploaded" src={img2URL} style={{ width: "200px" }} />
                    </div>
                }
                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(3)
                            setEditableImage(img3URL)
                            setEditingImage(true)
                        }} className='hidden md:flex absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='hidden md:flex' alt="Not uploaded" src={img3URL} style={{ width: "300px" }} />
                    </div>
                }
                {doneGeneration && 
                    <div className='relative'>
                        <div onClick={()=>{
                            setCurrentChangeIndex(3)
                            setEditableImage(img3URL)
                            setEditingImage(true)
                        }} className='flex md:hidden absolute right-2 top-2 p-2 rounded-full bg-gray-200 hover:bg-gray-300  cursor-pointer items-center justify-center'><FaEdit size={18}/></div>
                        <img className='flex md:hidden' alt="Not uploaded" src={img3URL} style={{ width: "200px" }} />
                    </div>
                }

                
                </div>
                
            </div>}
            {doneGeneration && <div className='w-full max-w-7xl mb-4 mt-4 md:px-24 flex flex-col md:flex-row items-center justify-center'>
                
            </div>} */}
            <button
                    className="w-full mt-2 bg-primary-500 text-white rounded-lg font-bold uppercase px-6 py-2 text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>{
                        generateProductType()
                        
                    }}
                >
                {!generatingDetails && "Next Step"}
                {generatingDetails && <div className='flex items-center justify-center'><CircularProgress/> Generating Type...</div>}
                </button>
        </>
    )
}
